import React from "react";
import { unitContent } from "../../../Content/UnitContent";


function UnitServiceWhy() {

  const { whyUnit } = unitContent;
  return (<>
    <section className="why-we">
      <div className="container">
        <h2>{whyUnit.heading}</h2>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr4"></span>
              <h4>{whyUnit.cards.earlyError.title}</h4>
              <p>{whyUnit.cards.earlyError.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr16"></span>
              <h4>{whyUnit.cards.deliverOnTime.title}</h4>
              <p>{whyUnit.cards.deliverOnTime.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr34"></span>
              <h4>{whyUnit.cards.professional.title}</h4>
              <p>{whyUnit.cards.professional.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr5"></span>
              <h4>{whyUnit.cards.saveMoney.title}</h4>
              <p>{whyUnit.cards.saveMoney.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr2"></span>
              <h4>{whyUnit.cards.expertTest.title}</h4>
              <p>{whyUnit.cards.expertTest.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr37"></span>
              <h4>{whyUnit.cards.reliable.title}</h4>
              <p>{whyUnit.cards.reliable.description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>);
}

export default UnitServiceWhy;