import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';


function HomeFooter() {

    //Animation Effect On Load on Content
    const [animationUp, setAnimationUp] = useState("");
    const [visible, setVisible] = useState("hidden");
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        function triggerOnScroll() {
            setAnimationUp("animate__fadeInUp")
            setIsAnimated(true);
            setVisible("") // Set isAnimated to true when the animation is triggered
        }

        window.addEventListener("scroll", function () {
            const scrollPosition = window.scrollY; // Vertical scroll position
            if (scrollPosition > 6950 && !isAnimated) {
                triggerOnScroll();
            }
        });
    }, [isAnimated]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    //..

    return (<>
        <footer className={`footer animate__animated top-shadow ${animationUp}`}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-3 col-md-6">
                        {/* <img src={logo} className="foot-logo" alt="logo" width="182px" height="80px" loading="lazy"/> */}
                        <h1 className="miisco-logo">Miisco</h1>
                        <br />
                        <div className="address">
                            <address>Tulsi Residency, Infront of Siyaram Aashram Deeg Road, Goverdhan, Mathura <br />Uttar Pradesh - (281502)</address>
                        </div>
                        <h4><a href="tel:+917505954930" title="Call us">+91 9911065583</a></h4>
                        {/* <ul className="social-icons">
                            <li><Link to="https://www.facebook.com" className="facebook" title="Missco Facebook Page" target="_blank"></Link></li>
                            <li><Link to="https://twitter.com" className="twitter" title="Missco Twitter Profile" target="_blank"></Link></li>
                            <li><Link to="https://www.linkedin.com" className="linkedin" title="Missco LinkedIn Page" target="_blank"></Link></li>
                        </ul> */}
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <h3>Explore</h3>
                        <ul className="footlink">
                            <li><Link to="/" title="Home" onClick={scrollToTop}>Home</Link></li>
                            <li><Link to="/about" title="About Us" onClick={scrollToTop}>About Us</Link></li>
                            <li><Link to="/qa-consultation" title="QA Consultation" onClick={scrollToTop}>QA Consultation</Link></li>
                            {/* <li className="disabled-link"><Link to="/blog" title="Blog" onClick={scrollToTop}>Blog</Link></li> */}
                            <li><Link to="/contact" title="Contact Us" onClick={scrollToTop}>Contact Us</Link></li>
                            <li><Link to="/projects" title="Projects" onClick={scrollToTop}>Projects</Link></li>

                        </ul>
                    </div>
                    <div className="col-xl-6">
                        <h3>Services</h3>
                        <div className="row">
                            <div className="col-md-6">
                                <ul className="footlink">
                                    <li><Link to="/mobile-application-testing" title="Mobile Application Testing" onClick={scrollToTop}>Mobile Application Testing</Link></li>
                                    <li><Link to="/test-automation-services" title="Test Automation Services" onClick={scrollToTop}>Test Automation Services</Link></li>
                                    <li><Link to="/manual-testing-services" title="Manual Testing Services" onClick={scrollToTop}>Manual Testing Services</Link></li>
                                    <li><Link to="/api-testing-services" title="API Testing Services" onClick={scrollToTop}>API Testing Services</Link></li>
                                    <li><Link to="/load-and-performance-testing" title="Load and Performance Testing" onClick={scrollToTop}>Load and Performance Testing</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <ul className="footlink">
                                    <li><Link to="/security-testing-services" title="Security Testing Services" onClick={scrollToTop}>Security Testing Services</Link></li>
                                    <li><Link to="/compatibility-testing-services" title="Compatibility Testing Services" onClick={scrollToTop}>Compatibility Testing Services</Link></li>
                                    <li><Link to="/unit-testing-services" title="Unit Test Service" onClick={scrollToTop}>Unit Test Service</Link></li>
                                    <li><Link to="/cross-browser-testing-services" title="Cross Browser Testing Service" onClick={scrollToTop}>Cross Browser Testing Service</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="row justify-content-between">
                        <div className="col-auto">
                            <p>© 2023 Missco. All rights reserved.</p>
                        </div>
                        {/* <div className="col-auto disabled-link"><Link to="/terms-of-use" title="Terms of Use">Terms of Use</Link> &nbsp;|&nbsp; <Link to="/privacy-statement" title="Privacy Statement">Privacy Statement</Link> &nbsp;|&nbsp; <Link to="/sitemap" title="Sitemap">Sitemap</Link></div> */}
                    </div>
                </div>
            </div>
        </footer>
    </>)

}


export default HomeFooter;