import React from "react";
import { apiContent } from "../../../Content/ApiContent";


function ApiServiceWhy() {

  const { whyAPI } = apiContent;
  return (<>
    <section className="why-we">
      <div className="container">
        <h2>{whyAPI.heading}</h2>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr17"></span>
              <h4>{whyAPI.cards.reliable.title}</h4>
              <p>{whyAPI.cards.reliable.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr18"></span>
              <h4>{whyAPI.cards.apiPerformance.title}</h4>
              <p>{whyAPI.cards.apiPerformance.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr19"></span>
              <h4>{whyAPI.cards.businessFocus.title}</h4>
              <p>{whyAPI.cards.businessFocus.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr12"></span>
              <h4>{whyAPI.cards.competitivePrice.title}</h4>
              <p>{whyAPI.cards.competitivePrice.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr20"></span>
              <h4>{whyAPI.cards.efficientApi.title}</h4>
              <p>{whyAPI.cards.efficientApi.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr21"></span>
              <h4>{whyAPI.cards.customApi.title}</h4>
              <p>{whyAPI.cards.customApi.description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>);
}

export default ApiServiceWhy;