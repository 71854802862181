import React from "react";

function ReviewsCompanies() {
    const importAll = (context) => context.keys().map(context);
    const images = importAll(require.context('../../partner-img', false, /\.(png|jpe?g|svg)$/));


    return (<>
        <section className="home-contact top-shadow">
            <div className="container">
                <div className={`parter-grid`}>
                    <div className={``}>
                        <h2>100+</h2>
                        <p>Reviews On Different Platforms</p>
                    </div>
                    {/*<div className={`col-lg-6 partner-items`}> */}
                    <div><img src={images[0]} alt="" /></div>
                    <div><img src={images[2]} alt="" /></div>
                    <div><img src={images[1]} alt="" /></div>
                    {/* </div> */}
                </div>
            </div>
        </section>
    </>)

}


export default ReviewsCompanies;