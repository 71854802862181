import React from "react";
import { qaContent } from "../../Content/QaConsultationContent";


function QaConsultServiceWhy() {

  const { whyQa } = qaContent;
  return (<>
    <section className="why-we">
      <div className="container">
        <h2>{whyQa.heading}</h2>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr33"></span>
              <h4>{whyQa.cards.tailored.title}</h4>
              <p>{whyQa.cards.tailored.description}.</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr43"></span>
              <h4>{whyQa.cards.expertise.title}</h4>
              <p>{whyQa.cards.expertise.description}.</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr44"></span>
              <h4>{whyQa.cards.comprehensive.title}</h4>
              <p>{whyQa.cards.comprehensive.description}.</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr45"></span>
              <h4>{whyQa.cards.monitoring.title}</h4>
              <p>{whyQa.cards.monitoring.description}.</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr46"></span>
              <h4>{whyQa.cards.costEffective.title}</h4>
              <p>{whyQa.cards.costEffective.description}.</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr47"></span>
              <h4>{whyQa.cards.dedicated.title}</h4>
              <p>{whyQa.cards.dedicated.description}.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>);
}

export default QaConsultServiceWhy;