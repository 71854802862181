import React from "react";
import { qaClientContent } from "../../Content/QaClientContent";
import { constants } from '../../config/Config'





function QaClient() {
    return (<>
        <section className="light-bg qa-client">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-5">
                        <h2>{qaClientContent.qaClient}</h2>
                    </div>
                    <div className="col-lg-7">
                        <img src={`${constants.imagePath}/qa-client.webp`} alt="QA Certificates" loading="lazy" />
                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default QaClient;