import React from "react";
import { unitContent } from "../../../Content/UnitContent";
import { constants } from "../../../config/Config";


function UnitServiceDescriptionOne() {

  const { descriptionOne } = unitContent;
  return (<>
    <section className="sr-top-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="sr-top-section-img">
              <img src={`${constants.imagePath}/unit-testing-service-reporting.webp`} alt="unit testing service reporting" width="735px" height="495px" />
            </div>
          </div>
          <div className="col-lg-6">
            <h2>{descriptionOne.heading}</h2>
            <p>{descriptionOne.paragraph1}</p>
            <p>{descriptionOne.paragraph2}</p>
            <ul className="inr-listing">
              {descriptionOne.bullets.map((value, index) => {
                return <li key={index}>{value}</li>
              })};
            </ul>
          </div>
        </div>
      </div>
    </section>

  </>);
}

export default UnitServiceDescriptionOne;