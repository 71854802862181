import React, { useEffect } from 'react';

function MyHubSpotForm() {


    var rendered = false;
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//js.hsforms.net/forms/embed/v2.js';
        script.charset = 'utf-8';
        script.async = true; // Use async to prevent blocking
        script.defer = true; // Use defer for compatibility

        const clientFormElement = document.getElementById('clientform');
        if (clientFormElement && !rendered) {
            rendered = true;
            clientFormElement.appendChild(script);

            script.onload = () => {
                if (window.hbspt) {
                    window.hbspt.forms.create({
                        region: 'na1',
                        portalId: '44370833',
                        formId: 'c1c29065-a6c0-4306-a64b-180f580ee82a'
                    });
                }
            };


        };

    }, []);


    return (
        <div id="clientform">
            {/* Your form will be inserted here */}
        </div>
    );
}

export default MyHubSpotForm;
