export const manualContent = {
    descriptionOne: {
        heading: "Manual Testing Services",
        paragraph1: "Manual software testing services play a crucial role in ensuring the flawless performance of your software. Even in today's automated world, manual testing is essential to double-check your software and certify its readiness for release.",
        paragraph2: "Miisco helps you identify and eliminate issues and glitches that may affect usability or efficacy. Our manual testing company offers the following services:",
        bullets: [
            "Comprehensive testing of all software features for optimal results.",
            "Testing apps for functionality across multiple browsers, OS, and databases.",
            "Validation of functionality and user flows within the software.",
            "Identification of potential outcomes of configuration changes.",
            "Testing network compatibility for user convenience.",
            "Ensuring software compatibility across various devices.",
            "UX testing for a user-friendly experience.",
        ],
    },

    descriptionTwo: {
        heading: "The Benefits of Manual Testing",
        paragraph1: "While automation testing speeds up the process, manual software testing services offer thorough testing for all software types. Manual testing, done from a user's perspective, enhances product usability and delivers an optimal user experience.",
        paragraph2: "Miisco, a manual testing company, assists with the following:",
        bullets: [
            "Understanding user interaction with the app.",
            "Adapting testing processes and techniques for each product.",
            "Providing platform coverage tests for ideal performance.",
            "Scalability tailored to project requirements.",
            "Improving user experience and releasing software with fewer defects.",
        ],
    },

    whyManual: {
        heading: "Why Choose Miisco for Manual Testing Services?",
        cards: {
            serviceKnowledge: {
                title: "In-depth Service Knowledge",
                description: "Our manual testers possess step-by-step knowledge of the testing process and provide detailed reports tailored to your needs.",
            },

            optimalUsability: {
                title: "Optimal Usability Testing",
                description: "Our usability testing evaluates user interactions, ensuring a seamless user experience, with QA testers closely observing the process.",
            },

            earlyIssue: {
                title: "Early Issue Detection",
                description: "Exploratory testing allows our manual testers to detect errors outside of planned testing and start the debugging process more quickly.",
            },

            performanceTesting: {
                title: "Ad-hoc Testing",
                description: "Our expert testers perform ad-hoc testing randomly and unplanned, detecting potential errors in the early stages without following strict documentation.",
            },

            expertTeam: {
                title: "Dedicated Expert Team",
                description: "Miisco testers are experienced QA engineers and testers, handpicked for their expertise in developing projects.",
            },

            onTimeDelivery: {
                title: "On-time Delivery",
                description: "We prioritize timely project delivery to meet the demands of today's competitive market.",
            },
        },
    },
    contact: {
        heading: "Get in Touch",
        description: "As a highly experienced manual testing company, we offer manual testing services to companies in various fields. Hire manual testers now to identify product bugs, failures, and crashes. Contact us for expert testing services.",
    },

    testingProcess: {
        heading: "Our Manual Testing Process",
        description: "Miisco follows a systematic process to deliver software testing projects on time. Our process includes several phases:",
        cards: {
            analysis: "Requirement Analysis",
            planning: "Test Planning",
            development: "Test Case Development",
            environment: "Test Environment Setup",
            execution: "Test Execution",
            closure: "Test Closure",
        },
    },

    blog: {
        one: {
            title: "Performing Performance Testing Using JMeter",
            description: "Learn how to utilize JMeter, a powerful performance testing tool, to run performance and load tests for web applications effectively.",
        },
        two: {
            title: "Manual Testing: Types, Pros, Cons & How to Perform",
            description: "Explore the world of manual testing, including its types, advantages, disadvantages, and effective testing techniques to ensure software quality.",
        },
        three: {
            title: "Database (MSSQL) Testing using JMeter",
            description: "Discover how to perform database testing on MSSQL using JMeter, ensuring performance and reliability for a hassle-free user experience.",
        },
    },
};
