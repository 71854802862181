import React from 'react';

function AboutBanner() {
  return (
    <>

      <section className="inner-banner container">
        <div>
          <h1>About Us</h1>
          <p>
            <span style={{ opacity: 0.8 }}>Company</span> &nbsp;-&nbsp; About Us
          </p>
        </div>
      </section>

    </>
  );
}


export default AboutBanner;