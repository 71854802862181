import React from 'react';
import { aboutContent } from '../../../Content/AboutContent';
function AboutAboutUs() {

  const { heading, description, cards, mission, vision } = aboutContent;
  return (
    <>

      <section className="home-about inner-about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-6 padr-10">
              <h2>{heading}</h2>
              <p>{description.One}</p>
              <p>{description.Two}</p>
              <p>{description.Three}</p>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="row">

                {cards.map((value, index) => {
                  return (
                    <div className="col-md-6">
                      <div className="card">
                        <h4>{value.title}</h4>
                        <p>{value.describe}</p>
                      </div>
                    </div>
                  )
                })}

                {/* <div className="col-md-6">
                  <div className="card">
                    <h4>{cards.years}</h4>
                    <p>Years in QA Business</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <h4>{cards.softwareTesters}</h4>
                    <p>Software Testers</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <h4>{cards.testedApps}</h4>
                    <p>Mobile Apps Tested</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <h4>{cards.sitesTested}</h4>
                    <p>Sites Tested</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row vision-mission-box">
            <div className="col-md-6">
              <div className="bg-light-red">
                <h3>Our Vision</h3>
                <p>{vision}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bg-light-blue">
                <h3>Our Mission</h3>
                <p>{mission}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}


export default AboutAboutUs;