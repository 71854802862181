export const developmentContent = {
    mobileAppDevelopment: {
        descriptionOne: {
            heading: "Mobile Application Development For Cross Platform",
            paragraph: "Developing a cross-platform mobile app allows you to reach users on multiple platforms (such as iOS and Android) with a single codebase, reducing development costs and time-to-market.",
            paragraph2: "By targeting multiple platforms, your app can reach a larger audience of users, increasing your app's visibility, downloads, and potential revenue streams.",
            bulletPoints: [
                "We specialize in creating high-quality mobile apps tailored to your specific needs",
                "Our team works closely with you to understand your requirements and deliver custom solutions that meet your business goals",
                "We develop apps that work seamlessly across all major platforms, including iOS, Android, and Windows",
                "We follow an agile development methodology, allowing for flexibility and rapid iterations to deliver results quickly",
                "Rigorous testing procedures are implemented to ensure bug-free, reliable, and secure apps",
                "We provide ongoing maintenance and support services to keep your app running smoothly and up-to-date with the latest technologies",
                "We believe in transparent communication and keep you informed at every stage of the development process."
            ]
        },
        descriptionTwo: {
            heading: "Benifits of Mobile Application",
            paragraph: "Advantages of mobile applications include enhanced accessibility, convenience, and personalized experiences, leading to improved customer engagement, brand recognition, and revenue opportunities.",
            bulletPoints: [
                "Mobile apps provide users with instant access to information, services, and entertainment anytime, anywhere, using their smartphones or tablets.",
                "Mobile apps offer personalized experiences, push notifications, and interactive features that engage users and encourage repeat usage.",
                "Mobile apps streamline processes, simplify tasks, and provide convenient solutions, enhancing overall customer satisfaction and loyalty.",
                "With billions of smartphone users worldwide, mobile apps enable businesses to reach a broader audience and tap into new markets globally.",
                "Mobile apps can leverage device features like GPS, camera, accelerometer, and push notifications, enhancing functionality and user experience."
            ]
        },
        whyMobileApp: {
            heading: "Why Choose MIISCO For Mobile Application Development.",
            cards: {
                serviceKnowledge: {
                    title: "In-depth Service Knowledge",
                    description: "Our developer possess lot of knowledge of Cross platform mobile apllication development. They are friendly with bussines logics, which have to implement bussiness Ideas into a software."         },
    
                optimalUsability: {
                    title: "Optimal Usability Testing",
                    description: "Our usability testing evaluates user interactions, ensuring a seamless user experience, with QA testers closely observing the process.",
                },
    
                earlyIssue: {
                    title: "Early Issue Detection",
                    description: "Exploratory testing allows our manual testers to detect errors outside of planned testing and start the debugging process more quickly.",
                },
    
                performanceTesting: {
                    title: "Bug Free Software",
                    description: "Our expert testers perform ad-hoc testing randomly and unplanned, detecting potential errors in the early stages without following strict documentation.",
                },
    
                 expertTeam: {
                    title: "Dedicated Expert Team",
                    description: "Miisco developers are experienced, they always work according to project requirement & Client Satisfaction.",
                },
    
                onTimeDelivery: {
                    title: "On-time Delivery",
                    description: "We prioritize timely project delivery to meet the demands of today's competitive market.",
                },
            }
        },
        contact: {
            heading: "Get in Touch",
            description: "As a highly experienced development company, we offer mobile as well as web application services to companies in various fields. Hire Cross plateform mobile application developer now. Contact us for satisfying product services.",
        },  
        developmentProcess: {
            heading: "Our Mobile Development Process",
            description: "Miisco follows a systematic process to deliver mobile software developing projects on time. Our process includes several phases:",
            cards: {
                analysis: "Requirement Gathering",
                planning: "Planning and Strategy",
                development: "Design",
                environment: "Development",
                execution: "Testing",
                closure: "Deployment",
                update: "Maintenance & Update"
            },
        },    
        blog: {
            one: {
                title: "Performing Performance using Flutter.",
                description: "Learn how to utilize JMeter, a powerful performance testing tool, to run performance and load tests for web applications effectively.",
            },
            two: {
                title: "Manual Testing: Types, Pros, Cons & How to Perform",
                description: "Explore the world of manual testing, including its types, advantages, disadvantages, and effective testing techniques to ensure software quality.",
            },
            three: {
                title: "Database (MSSQL) Testing using JMeter",
                description: "Discover how to perform database testing on MSSQL using JMeter, ensuring performance and reliability for a hassle-free user experience.",
            },
        } 
    },
    
    WebDevelopment: {
        descriptionOne: {
            heading: "Web Application Development And Maintenance",
            paragraph: "A website establishes your online presence, allowing you to showcase your business, products, and services to a global audience 24/7.",
            paragraph2: "A website provides a convenient platform for customers to learn about your business, browse products, and make purchases from anywhere, at any time.",
            bulletPoints: [
                "Benefit from our team's extensive expertise and experience in website development.",
                "We offer personalized solutions tailored to your business requirements, ensuring that your website stands out and meets your unique goals.",
                "Our websites are optimized for search engines, helping you rank higher in search results.",
                "Our scalable solutions allow your website to grow and evolve with your business.",
                "We prioritize website security, implementing robust measures to protect your data, user information, and online transactions, providing peace of mind and trust for both you and your visitors.",
                "Our dedicated support team provides ongoing assistance, maintenance, and troubleshooting.",
            ]
        },
        descriptionTwo: {
            heading: "Benifits Of Having a Website",
            paragraph: "Advantages of a website include enhanced accessibility, convenience, and personalized experiences, leading to improved customer engagement, brand recognition, and revenue opportunities.",
            bulletPoints: [
                "Website provide users with instant access to information, services, and entertainment anytime, anywhere, using their smartphones or tablets.",
                "Website apps offer personalized experiences, push notifications, and interactive features that engage users and encourage repeat usage.",
                "Website apps streamline processes, simplify tasks, and provide convenient solutions, enhancing overall customer satisfaction and loyalty.",
                "With billions of smartphone users worldwide, mobile apps enable businesses to reach a broader audience and tap into new markets globally.",
                "Web apps can leverage device features like GPS, camera, accelerometer, and push notifications, enhancing functionality and user experience."
            ]
        },
        whyWebApp: {
            heading: "Why Choose MIISCO For Website Application Development.",
            cards: {
                serviceKnowledge: {
                    title: "In-depth Service Knowledge",
                    description: "Our developer possess lot of knowledge of Cross platform mobile apllication development. They are friendly with bussines logics, which have to implement bussiness Ideas into a software."         },
    
                optimalUsability: {
                    title: "Optimal Usability Testing",
                    description: "Our usability testing evaluates user interactions, ensuring a seamless user experience, with QA testers closely observing the process.",
                },
    
                earlyIssue: {
                    title: "Early Issue Detection",
                    description: "Exploratory testing allows our manual testers to detect errors outside of planned testing and start the debugging process more quickly.",
                },
    
                performanceTesting: {
                    title: "Bug Free Software",
                    description: "Our expert testers perform ad-hoc testing randomly and unplanned, detecting potential errors in the early stages without following strict documentation.",
                },
    
                 expertTeam: {
                    title: "Dedicated Expert Team",
                    description: "Miisco developers are experienced, they always work according to project requirement & Client Satisfaction.",
                },
    
                onTimeDelivery: {
                    title: "On-time Delivery",
                    description: "We prioritize timely project delivery to meet the demands of today's competitive market.",
                },
            }
        },
        contact: {
            heading: "Get in Touch",
            description: "As a highly experienced development company, we offer mobile as well as web application services to companies in various fields. Hire Cross plateform mobile application developer now. Contact us for satisfying product services.",
        },  
        developmentProcess: {
            heading: "Our Mobile Development Process",
            description: "Miisco follows a systematic process to deliver mobile software developing projects on time. Our process includes several phases:",
            cards: {
                analysis: "Requirement Gathering",
                planning: "Planning and Strategy",
                development: "Design",
                environment: "Development",
                execution: "Testing",
                closure: "Deployment",
                update: "Maintenance & Update"
            },
        },    
        blog: {
            one: {
                title: "Performing Performance using Flutter.",
                description: "Learn how to utilize JMeter, a powerful performance testing tool, to run performance and load tests for web applications effectively.",
            },
            two: {
                title: "Manual Testing: Types, Pros, Cons & How to Perform",
                description: "Explore the world of manual testing, including its types, advantages, disadvantages, and effective testing techniques to ensure software quality.",
            },
            three: {
                title: "Database (MSSQL) Testing using JMeter",
                description: "Discover how to perform database testing on MSSQL using JMeter, ensuring performance and reliability for a hassle-free user experience.",
            },
        } 
    }
}