import React from "react";
import SecurityServiceDescriptionOne from "./SecurityServiceDescriptionOne";
import SecurityServiceWhy from "./SecurityServiceWhy";
import SecurityServiceContact from "./SecurityServiceContact";
import SecurityTesting from "./SecurityTesting";
import SecurityServiceDescriptionTwo from "./SecurityServiceDescriptionTwo";
import SecurityServiceBanner from "./SecurityServiceBanner";
import SecurityServiceBlog from "./SecurityServiceBlog";
import QaClient from "../../Common/QaClient";


function SecurityServicePage() {
    return (<>
        <SecurityServiceBanner />
        <SecurityServiceDescriptionOne />
        <SecurityServiceDescriptionTwo />
        <SecurityServiceWhy />
        <SecurityServiceContact />
        <SecurityTesting />
        {/* <SecurityServiceBlog /> */}
        <QaClient />
    </>);
}

export default SecurityServicePage;