
import React from "react";
import HomeAbout from "./HomeAbout";
import HomeBanner from "./HomeBanner";
import HomeContact from "./HomeContact";
import HomeServices from "./HomeServices";
import HomeWhyChoose from "./HomeWhyChoose";
// import HomeBlog from "./HomeBlog";
import HomeFeedback from './HomeFeedback'
import HomeProject from "./HomeProject";
import QaClient from '../Common/QaClient'
import HomeWorkSectors from "./HomeWorkSectors";
import ReviewsCompanies from "./ReviewsCompanies";

function HomePage() {
    return (<>
        <body className='home-bg'>
            {/* <Header /> */}
            <HomeBanner />
            <HomeAbout />
            <HomeWorkSectors />
            <HomeWhyChoose />
            <HomeProject />
            <ReviewsCompanies />
            <HomeServices />
            <HomeContact />
            <HomeFeedback />
            <QaClient />
            {/* <HomeBlog /> */}
        </body>
    </>);
}


export default HomePage;