export const automationContent = {
    descriptionOne: {
        heading: "Test Automation Services for Efficient QA",
        paragraph: "Miisco offers professional test automation services to boost your business platform's speed and efficiency. Our intelligent automation frameworks minimize QA costs while enhancing testing interfaces. Our automation testing company provides:",
        bullets: [
            "Comprehensive test automation feasibility analysis",
            "Configuration of testing tools (Selenium, Ranorex, REST Assured, Apache JMeter, etc.)",
            "Tailored business test automation frameworks",
            "Seamless integration of test automation into the CI/CD pipeline",
            "Effective test data preparation and script creation",
            "Continuous optimization for increased efficiency and cost reduction",
        ],
    },

    descriptionTwo: {
        heading: "Benefits of Automation Testing Services",
        paragraph1: "Automated tests create a robust safety net for future releases. With our years of experience, our expert testers offer automated testing services for websites, mobile apps, and various software. You can trust that no new features will cause regressions, and if they do, our test automation services will catch them.",
        paragraph2: "Our automation testing company helps you:",
        bullets: [
            "Significantly reduce testing costs by up to 20-40 percent",
            "Shorten testing times by up to 20 percent",
            "Enhance accuracy and minimize severe defects in production",
            "Achieve multiple daily releases",
            "Support large projects with continuous testing needs",
        ],
    },

    whyAutomation: {
        heading: "Why Choose Miisco as Your Automation Testing Partner?",
        cards: {
            customTest: {
                title: "Customized Testing Solutions",
                description: "We offer custom testing for browsers, mobile apps, operating systems, and database validation to meet your specific requirements.",
            },

            customService: {
                title: "Personalized Service Estimates",
                description: "We're here for the long term, but if you need short-term test automation services, we can provide a personalized estimate to fit your needs.",
            },

            bestSolution: {
                title: "Comprehensive Solutions",
                description: "Our team stays updated on the latest trends and can handle various tools. We also provide manual testing services, API testing services, and QA consultation.",
            },

            effortless: {
                title: "Effortless Onboarding",
                description: "Once you share your project details, we'll swiftly initiate testing automation services.",
            },

            professional: {
                title: "Expert Testers",
                description: "Our highly experienced testing team delivers cost-efficient services that stand out in the industry.",
            },

            competitive: {
                title: "Competitive Pricing",
                description: "Budget matters when choosing an automation testing company. At Miisco, you can hire testers with competitive prices that align with your budget.",
            },
        },
    },
    contact: {
        heading: "Contact Our Team at Miisco for Accelerated Success",
        description: "Whether you're launching or expanding your digital business, professional testers are essential for faster, cost-effective results. When you're ready, contact our team at Miisco to accelerate your digital success by reducing test cycle time and costs.",
    },

    testingProcess: {
        heading: "Our Methodical Testing Process",
        description: "Miisco follows a systematic process to deliver software testing projects promptly. Our process includes several key phases:",
        cards: {
            analysis: "Requirement Analysis",
            planning: "Test Planning",
            development: "Test Case Development",
            environment: "Test Environment Setup",
            execution: "Test Execution",
            closure: "Test Closure",
        },
    },
    blog: {
        one: {
            title: "Web Application Security: Identifying Vulnerabilities and Prevention",
            description: "Security testing is crucial to uncover system vulnerabilities and protect data and resources from potential intruders. Learn how to enhance your web application security.",
        },
        two: {
            title: "Performance Testing Using JMeter: A Beginner's Guide",
            description: "JMeter is a powerful open-source tool for analyzing and measuring software performance. Explore the basics of performance testing and how to use JMeter effectively.",
        },
        three: {
            title: "How to Conduct Performance Testing with JMeter",
            description: "Learn the steps to perform performance and load testing for web applications using JMeter. Create records, generate test reports, and optimize your application's performance.",
        },
    },
}
