import React from "react";


function CompatibilityTesting() {
  return (<>
    <section className="light-bg software-testing">
      <div className="container">
        <h2>Our Software Testing Process</h2>
        <p>Pixel QA follows a systematic process to deliver software testing projects to our clients. Also, we ensure completing the tasks within the set deadlines online. Our process includes several phases are like:</p>
        <div className="row">
          <div className="col-xxl-2 col-lg-4 col-6">
            <div className="row flex-column">
              <div className="round-card">
                <span className="sr1"></span>
              </div>
              <h5>Requirement Analysis</h5>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-4 col-6">
            <div className="row flex-column flex-xxl-column-reverse">
              <div className="round-card">
                <span className="sr2"></span>
              </div>
              <h5>Test Planning</h5>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-4 col-6">
            <div className="row flex-column">
              <div className="round-card">
                <span className="sr3"></span>
              </div>
              <h5>Test Case Development</h5>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-4 col-6">
            <div className="row flex-column flex-xxl-column-reverse">
              <div className="round-card">
                <span className="sr4"></span>
              </div>
              <h5>Test Environment Setup</h5>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-4 col-6">
            <div className="row flex-column">
              <div className="round-card">
                <span className="sr5"></span>
              </div>
              <h5>Test Execution</h5>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-4 col-6">
            <div className="row flex-column flex-xxl-column-reverse">
              <div className="round-card last">
                <span className="sr6"></span>
              </div>
              <h5>Test Closure</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>);
}

export default CompatibilityTesting;