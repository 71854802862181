
export default function MobileAppDevelopmentBanner() {
    return (
        <section className="inner-banner container">
            <div>
                <h1>Mobile Application Development Services</h1>
                <p>
                    <span style={{ opacity: 0.8 }}>Services</span> &nbsp;-&nbsp; Mobile Application Development
                </p>
            </div>
        </section>
    )
}