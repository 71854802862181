import React from "react";
import AutomationServiceDescriptionOne from "./AutomationServiceDescriptionOne";
import AutomationServiceWhy from "./AutomationServiceWhy";
import AutomationServiceContact from "./AutomationServiceContact";
import AutomationTesting from "./AutomationTesting";
import AutomationServiceDescriptionTwo from "./AutomationServiceDescriptionTwo";
import AutomationServiceBanner from "./AutomationServiceBanner";
import AutomationServiceBlog from "./AutomationServiceBlog";
import QaClient from "../../Common/QaClient";


function AutomationServicePage() {
    return (<>
        <AutomationServiceBanner />
        <AutomationServiceDescriptionOne />
        <AutomationServiceDescriptionTwo />
        <AutomationServiceWhy />
        <AutomationServiceContact />
        <AutomationTesting />
        {/* <AutomationServiceBlog /> */}
        <QaClient />
    </>);
}

export default AutomationServicePage;