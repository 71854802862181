import React, { useEffect, useState } from "react";
import { content } from "../../Content/HomeContent";
import { projectContent } from "../../Content/ProjectContent";

function HomeProject() {

    //Animation Effect On Load on Content
    const [animationLeft, setAnimationLeft] = useState("");
    const [animationRight, setAnimationRight] = useState("");
    const [visible, setVisible] = useState("hidden")
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        function triggerOnScroll() {
            setAnimationLeft("animate__fadeInLeft");
            setAnimationRight("animate__fadeInRight");
            setIsAnimated(true);
            setVisible("") // Set isAnimated to true when the animation is triggered
        }

        window.addEventListener("scroll", function () {
            const scrollPosition = window.scrollY; // Vertical scroll position
            if (scrollPosition > 2100 && !isAnimated) {
                triggerOnScroll();
            }
        });
    }, [isAnimated]);
    //..

    const { project } = content;
    const { projectsDescription } = projectContent;
    return (<>
        <section className="feedback">
            <div className="container">
                <div className={`row justify-content-between align-items-center ${visible}`}>
                    <div className={`col-lg-6 col-xl-5 animate__animated ${animationLeft}`}>
                        <h2>{project.heading}</h2>
                        <p>{project.description}</p>
                        <a href="/projects" className="btn gradient-btn">View More</a>
                    </div>
                    <div className={`col-lg-6 animate__animated ${animationRight} `}>
                        <div className="custom-card project-card home-card-shadow">
                            <div className="card-body">
                                <div className="content">
                                    <h4>{projectsDescription[2].title}</h4>
                                    <p>{projectsDescription[2].description}</p>
                                </div>
                            </div>
                        </div>
                        <div className="custom-card margin-left project-card home-card-shadow">
                            <div className="card-body">
                                <div className="content">
                                    <h4>{projectsDescription[3].title}</h4>
                                    <p>{projectsDescription[3].description}</p>
                                </div>
                            </div>
                        </div>
                        <div className="custom-card project-card home-card-shadow">
                            <div className="card-body">
                                <div className="content">
                                    <h4>{projectsDescription[4].title}</h4>
                                    <p>{projectsDescription[4].description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)

}


export default HomeProject;