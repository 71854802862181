import React, { useState } from "react";
import { content } from "../../Content/HomeContent";
import { useEffect } from "react";
import { aboutContent } from "../../Content/AboutContent";

function HomeAbout() {
    const [animationLeft, setAnimationLeft] = useState("");
    const [animationRight, setAnimationRight] = useState("");
    const [visible, setVisible] = useState("hidden")
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        function triggerOnScroll() {
            setAnimationLeft("animate__fadeInLeft");
            setAnimationRight("animate__fadeInRight");
            setIsAnimated(true);
            setVisible("") // Set isAnimated to true when the animation is triggered
        }

        window.addEventListener("scroll", function () {
            const scrollPosition = window.scrollY; // Vertical scroll position
            if (scrollPosition > 550 && !isAnimated) {
                triggerOnScroll();
            }
        });
    }, [isAnimated]);

    const { about } = content;
    const { cards } = aboutContent;
    return (<>

        <section className="home-about top-shadow">
            <div className="container">
                <div className={`row align-items-center ${visible}`}>
                    <div className={`col-lg-6 padr-10 animate__animated ${animationLeft}`}>
                        <h2>{about.heading}</h2>
                        <p>{about.firstDescription}</p>
                        <p>{about.secondDescription}</p>
                        <a href="/about" className="btn gradient-btn">{about.buttonText}</a>
                    </div>
                    <div className={`col-lg-6 padr-10 animate__animated ${animationRight}`} >
                        <div className="row">
                            {cards.map((value, index) => {
                                return <div className="col-md-6" key={index}>
                                    <div className="card">
                                        <h4>{value.title}</h4>
                                        <p>{value.describe}</p>
                                    </div>
                                </div>
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </section >
    </>)

}


export default HomeAbout;