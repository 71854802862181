import QaClient from "../../Common/QaClient";
import WebDevelopmentBanner from "./WebDevelopmentBanner";
import WebDevelopmentBlog from "./WebDevelopmentBlog";
import WebDevelopmentContact from "./WebDevelopmentContact";
import WebDevelopmentProcess from "./WebDevelopmentProcess";
import WebDevelopmentWhy from "./WebDevelopmentWhy";
import WebDevelopmentDescription from "./WebdevelopmentDescription";

export default function WebDevelopmentPage()
 {
    return (
        <>
           <WebDevelopmentBanner />
           <WebDevelopmentDescription />
           <WebDevelopmentWhy />
           <WebDevelopmentContact />
           <WebDevelopmentProcess />
           {/* <WebDevelopmentBlog /> */}
           <QaClient />
        </>
    )
 }