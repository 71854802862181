import React from "react";
import { developmentContent } from "../../../Content/DevelopmentContent";
import { manualContent } from "../../../Content/ManualContent";
import { constants } from "../../../config/Config";

export default function MobileApplicationDevelopmentdescription() {
    const { descriptionOne, descriptionTwo } = developmentContent.mobileAppDevelopment;
    return (
        <>
            <section className="sr-top-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="sr-top-section-img">
                                <img src={`${constants.imagePath}/mobileApplicationDevelopement.avif`} alt="testLink manual testing services navigation" width="735px" height="495px" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h2>{descriptionOne.heading}</h2>
                            <p>{descriptionOne.paragraph}</p>
                            <p>{descriptionOne.paragraph2}</p>
                            <ul className="inr-listing">
                                {descriptionOne.bulletPoints.map((value, index) => {
                                    return <li key={index}>{value}</li>
                                })};
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sr-top-section light-bg">
                <div className="container">
                    <div className="row align-items-center flex-lg-row-reverse">
                        <div className="col-lg-6">
                            <div className="sr-top-section-img">
                                <img src={`${constants.imagePath}/mobileApplicationDevelopement2.avif`} loading="lazy" alt="testLink manual testing services status report" width="735px" height="495px" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h2>{descriptionTwo.heading}</h2>
                            <p>{descriptionTwo.paragraph}</p>
                            <p>{descriptionTwo.paragraph2}</p>
                            <ul className="inr-listing">
                                {descriptionTwo.bulletPoints.map((value, index) => {
                                    return <li key={index}>{value}</li>
                                })};
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
