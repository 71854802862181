import React from "react";
import { qaContent } from "../../Content/QaConsultationContent";


function ApiTesting() {

  const { testingTypes } = qaContent;
  return (<>
    <section className="home-services">
      <div className="container">
        <h2>{testingTypes.heading}</h2>
        <div className="row justify-content-center">
          <div className="col-lg-3 col-sm-6">
            <a href="/mobile-application-testing" className="card" title="Mobile Application Testing">
              <span className="sr1"></span>
              <p>{testingTypes.cards.mobile}</p>
            </a>
          </div>
          <div className="col-lg-3 col-sm-6">
            <a href="/test-automation-services" className="card" title="Test Automation Services">
              <span className="sr2"></span>
              <p>{testingTypes.cards.automation}</p>
            </a>
          </div>
          <div className="col-lg-3 col-sm-6">
            <a href="/manual-testing-services" className="card" title="Manual Testing Services">
              <span className="sr3"></span>
              <p>{testingTypes.cards.manual}</p>
            </a>
          </div>
          <div className="col-lg-3 col-sm-6">
            <a href="/api-testing-services" className="card" title="API Testing Services">
              <span className="sr4"></span>
              <p>{testingTypes.cards.api} </p>
            </a>
          </div>
          <div className="col-lg-3 col-sm-6">
            <a href="/load-and-performance-testing" className="card" title="Load and Performance Testing">
              <span className="sr5"></span>
              <p>{testingTypes.cards.load}</p>
            </a>
          </div>
          <div className="col-lg-3 col-sm-6">
            <a href="/security-testing-services" className="card" title="Security Testing Services">
              <span className="sr6"></span>
              <p>{testingTypes.cards.security}</p>
            </a>
          </div>
          <div className="col-lg-3 col-sm-6">
            <a href="/compatibility-testing-services" className="card" title="Compatibility Testing Services">
              <span className="sr7"></span>
              <p>{testingTypes.cards.compatibility}</p>
            </a>
          </div>
          <div className="col-lg-3 col-sm-6">
            <a href="/unit-testing-service" className="card" title="Unit Test Service">
              <span className="sr8"></span>
              <p>{testingTypes.cards.unit}</p>
            </a>
          </div>
          <div className="col-lg-3 col-sm-6">
            <a href="/cross-browser-testing-service" className="card" title="Cross Browser Testing Service">
              <span className="sr9"></span>
              <p>{testingTypes.cards.crossBrowser}</p>
            </a>
          </div>
        </div>
      </div>
    </section>
  </>);
}

export default ApiTesting;