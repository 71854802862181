import React from "react";
import { loadContent } from "../../../Content/LoadAndPerformanceContetn";


function LoadPerformanceServiceWhy() {

  const { whyLoad } = loadContent;
  return (<>
    <section className="why-we">
      <div className="container">
        <h2>{whyLoad.heading}</h2>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr22"></span>
              <h4>{whyLoad.cards.costEffective.title}</h4>
              <p>{whyLoad.cards.costEffective.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr23"></span>
              <h4>{whyLoad.cards.resolveError.title}</h4>
              <p>{whyLoad.cards.resolveError.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr24"></span>
              <h4>{whyLoad.cards.reliable.title}</h4>
              <p>{whyLoad.cards.reliable.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr25"></span>
              <h4>{whyLoad.cards.superiorLoad.title}</h4>
              <p>{whyLoad.cards.superiorLoad.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr26"></span>
              <h4>{whyLoad.cards.quickStart.title}</h4>
              <p>{whyLoad.cards.quickStart.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr27"></span>
              <h4>{whyLoad.cards.transparency.title}</h4>
              <p>{whyLoad.cards.transparency.description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>);
}

export default LoadPerformanceServiceWhy;