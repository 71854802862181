import React from 'react'
import Form from '../../Components/Common/Form';
import MyHubSpotForm from '../Common/MyHubSpotForm';


function ProjectContact() {
    return (<>
        <section className="light-bg inner-contact">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-6 col-xl-5">
                        <h2>Share Your QA Requirement With Us!!</h2>
                        <p>Our Missco solution experts will get back to you for a free QA consultation.</p>
                    </div>
                    <div className='col-lg-6'>
                        <MyHubSpotForm />
                    </div>
                </div>
            </div>
        </section>

    </>);
}

export default ProjectContact;