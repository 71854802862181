import React from "react";
import { manualContent } from "../../../Content/ManualContent";


function ManualServiceWhy() {

  const { whyManual } = manualContent;
  return (<>
    <section className="why-we">
      <div className="container">
        <h2>{whyManual.heading}</h2>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr13"></span>
              <h4>{whyManual.cards.serviceKnowledge.title}</h4>
              <p>{whyManual.cards.serviceKnowledge.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr14"></span>
              <h4>{whyManual.cards.optimalUsability.title}</h4>
              <p>{whyManual.cards.optimalUsability.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr4"></span>
              <h4>{whyManual.cards.earlyIssue.title}</h4>
              <p>{whyManual.cards.earlyIssue.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr15"></span>
              <h4>{whyManual.cards.performanceTesting.title}</h4>
              <p>{whyManual.cards.performanceTesting.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr2"></span>
              <h4>{whyManual.cards.expertTeam.title}</h4>
              <p>{whyManual.cards.expertTeam.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr16"></span>
              <h4>{whyManual.cards.onTimeDelivery.title}</h4>
              <p>{whyManual.cards.onTimeDelivery.description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>);
}

export default ManualServiceWhy;