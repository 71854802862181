import React from "react";
import LoadPerformanceServiceWhy from "./LoadPerformanceServiceWhy";
import LoadPerformanceServiceContact from "./LoadPerformanceServiceContact";
import LoadPerformanceTesting from "./LoadPerformanceTesting";
import LoadPerformanceServiceDescriptionTwo from "./LoadPerformanceServiceDescriptionTwo";
import LoadPerformanceServiceBanner from "./LoadPerformanceServiceBanner";
import LoadPerformanceServiceBlog from "./LoadPerformanceServiceBlog";
import LoadPerformanceServiceDescriptionOne from './LoadPerformanceDescriptionOne'
import QaClient from "../../Common/QaClient";


function LoadPerformanceServicePage() {
    return (<>
        <LoadPerformanceServiceBanner />
        <LoadPerformanceServiceDescriptionOne />
        <LoadPerformanceServiceDescriptionTwo />
        <LoadPerformanceServiceWhy />
        <LoadPerformanceServiceContact />
        <LoadPerformanceTesting />
        {/* <LoadPerformanceServiceBlog /> */}
        <QaClient />
    </>);
}

export default LoadPerformanceServicePage;