import React from "react";
import { mobileContent } from '../../../Content/MoblieContent'
import { constants } from "../../../config/Config";


function MobileServiceDescriptionTwo() {

  const { descriptionTwo } = mobileContent;
  return (<>
    <section className="sr-top-section light-bg">
      <div className="container">
        <div className="row align-items-center flex-lg-row-reverse">
          <div className="col-lg-6">
            <div className="sr-top-section-img">
              <img src={`${constants.imagePath}/apptim-mobile-testing-services-device-detail.webp`} loading="lazy" alt="apptim mobile testing services device detail" width="735px" height="495px" />
            </div>
          </div>
          <div className="col-lg-6">
            <h2>{descriptionTwo.heading}</h2>
            <p>{descriptionTwo.description}</p>
            <ul className="inr-listing">
              {descriptionTwo.bullets.map((value, index) => {
                return <li key={index}>{value}</li>
              })}
              {/* <li>Significantly improve your user retention rate as 48% of customers uninstall slow apps</li>
              <li>Create apps that maintain performance on any bandwidth</li>
              <li>Spend less time on debugging and more on innovation</li>
              <li>Develop market-ready apps while saving a tremendous amount by removing costly bugs</li>
              <li>Achieve end-to-end security against potential vulnerabilities</li>
              <li>Make sure that the app fits the smartphone's physical features</li> */}
            </ul>
          </div>
        </div>
      </div>
    </section>

  </>);
}

export default MobileServiceDescriptionTwo;