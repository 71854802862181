import React from "react";
import { loadContent } from "../../../Content/LoadAndPerformanceContetn";
import { constants } from "../../../config/Config";


function LoadPerformanceServiceDescriptionTwo() {

  const { descriptionTwo } = loadContent;
  return (<>
    <section className="sr-top-section light-bg">
      <div className="container">
        <div className="row align-items-center flex-lg-row-reverse">
          <div className="col-lg-6">
            <div className="sr-top-section-img">
              <img src={`${constants.imagePath}/performance-testing-services-report.webp`} alt="performance testing services report" loading="lazy" width="735px" height="495px" />
            </div>
          </div>
          <div className="col-lg-6">
            <h2>{descriptionTwo.heading}</h2>
            <p>{descriptionTwo.description}</p>
            <ul className="inr-listing">
              {descriptionTwo.bullets.map((value, index) => {
                return <li key={index}>{value}</li>
              })};
            </ul>
          </div>
        </div>
      </div>
    </section>
  </>);
}

export default LoadPerformanceServiceDescriptionTwo;