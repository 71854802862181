import React from 'react'


function TestimonialBanner() {
  return (<>
    <section className="inner-banner container">
      <div>
        <h1>Testimonials</h1>
        <p>
          <span style={{ opacity: 0.8 }}>Company</span> &nbsp;-&nbsp; Testimonials
        </p>
      </div>
    </section>

  </>);
}

export default TestimonialBanner;