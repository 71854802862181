import React from "react";
import { content } from "../../Content/HomeContent";
import 'animate.css';
import { constants } from '../../config/Config'

function HomeBanner() {
    const { home } = content;
    return (<>

        <section className="home-banner">
            <div className="container">
                <div className="row flex-row-reverse">
                    <div className="col-lg-6 home-banner-img animate__animated animate__fadeInRight">
                        <img src={`${constants.imagePath}/home-banner-img.png`} alt="software testing company" width="600px" height="680px" />
                    </div>
                    <div className="col-lg-6 animate__animated animate__fadeInLeft">
                        <h1>{home.heading}</h1>
                        <p>{home.description}</p>
                        <a href="/contact" className="btn">{home.buttonText}</a>

                    </div>
                </div>
            </div>
        </section>
    </>)

}


export default HomeBanner;