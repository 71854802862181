import React from 'react';
import Header from '../../Common/Header'
import AboutBanner from './AboutBanner';
import AboutAboutUs from './AboutAboutUs'
import AboutWhy from './AboutWhy';
import AboutContact from './AboutContact';
import QaClient from '../../Common/QaClient';

function AboutPage() {
    return (
        <body className="page-loaded page-scrolled">
            <Header />
            <AboutBanner />
            <AboutAboutUs />
            <AboutWhy />
            <AboutContact />
            <QaClient />
        </body>
    );
}


export default AboutPage;