import React from 'react'
import { projectContent } from '../../Content/ProjectContent'

function ProjectWorks() {
    const { pageHeading, pageDescription, projectsDescription } = projectContent;

    return (<>
        <section className="feedback inner-feedback">
            <div className="container">
                <h2>{pageHeading}</h2>
                <p className="title-text">{pageDescription}</p>
                <div className="row">
                    {projectsDescription.map((value, index) => {

                        return <div className="col-lg-6 project-card" key={index}>
                            <div className="custom-card">
                                <div className="card-body">
                                    <div className="content">
                                        <h4>{value.title}</h4>
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}

                </div>
            </div>
        </section>
    </>);
}

export default ProjectWorks;