export const loadContent = {
    descriptionOne: {
        heading: "Load and Performance Testing Services",
        description: "Optimize your software's load time, usability, and scalability with Miisco's load and performance testing services. Our comprehensive offerings include:",
        bullets: [
            "Load testing to ensure optimal software performance.",
            "Stress testing to evaluate software under higher loads.",
            "Scalability testing to assess performance with varying loads.",
            "Spike testing to measure performance during sudden load increases.",
            "Endurance testing to identify potential system errors like memory leaks.",
            "Volume testing to evaluate performance with different data amounts.",
        ],
    },

    descriptionTwo: {
        heading: "Why You Need Load and Performance Testing",
        description: "Ensure your app or website's optimal performance and stability with mobile app and website performance testing. Miisco, a performance testing company, helps you:",
        bullets: [
            "Prevent load and performance congestion.",
            "Identify product improvement opportunities before launch.",
            "Enhance software speed and stability.",
            "Boost the reliability of your software or website.",
            "Detect errors and assess performance under expected workloads.",
            "Measure error rates to minimize request-related errors.",
        ],
    },

    whyLoad: {
        heading: "Why Choose Miisco for Load and Performance Testing",
        cards: {
            costEffective: {
                title: "Cost-Effective Load Performance Testing",
                description: "Save costs with Miisco's enhanced test coverage and rapid error discovery. We prioritize quick debugging to reduce mean time to resolution (MTTR).",
            },

            resolveError: {
                title: "Resolve Errors Quickly",
                description: "Ensure optimal performance with Miisco's professional and up-to-date techniques. We use advanced tools tailored to your project.",
            },

            reliable: {
                title: "Reliable and Scalable Performance",
                description: "Miisco conducts extensive mobile app and website load testing to ensure consistent results even after upgrades.",
            },

            superiorLoad: {
                title: "Superior Load and Performance Testing Quality",
                description: "Our testers run automated and manual tests multiple times to ensure accuracy.",
            },

            quickStart: {
                title: "Quick Project Start",
                description: "Many Miisco projects start within days of notification. We prioritize your project to ensure timely delivery.",
            },

            transparency: {
                title: "Transparent Project Estimates",
                description: "Before starting a project, you'll receive estimates for time and costs associated with load performance testing.",
            },
        },
    },
    contact: {
        heading: "Get in Touch With Us",
        description: "Need a reliable company to manage your testing needs, faults, and traceability? Test your code's performance and scalability directly from your IDE. Contact Miisco's expert load testers for tailored services for your mobile app, website, or software.",
    },

    testingProcess: {
        heading: "Our Software Testing Process",
        description: "Miisco follows a systematic process to deliver software testing projects on time. Our process includes several phases:",
        cards: {
            analysis: "Requirement Analysis",
            planning: "Test Planning",
            development: "Test Case Development",
            environment: "Test Environment Setup",
            execution: "Test Execution",
            closure: "Test Closure",
        },
    },

    blog: {
        one: {
            title: "Understanding Software Quality Assurance",
            description: "Miisco Software QA Company ensures your software or app meets high-quality standards, allowing you to focus on enhancements rather than basic performance, security, or functionality issues.",
        },
        two: {
            title: "Challenges in Mobile Application Testing",
            description: "Mobile application testing is crucial, but it comes with challenges. Learn how to address mobile app testing challenges effectively in your projects.",
        },
        three: {
            title: "Essential SQL Terms for Database Testing",
            description: "Gain a fundamental understanding of SQL terms essential for effective database testing.",
        },
    },
};
