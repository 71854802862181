import React from "react";
import { automationContent } from "../../../Content/AutomationContent";

function AutomationServiceWhy() {
  const { whyAutomation } = automationContent;
  return (<>
    <section className="why-we">
      <div className="container">
        <h2>{whyAutomation.heading}</h2>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr7"></span>
              <h4>{whyAutomation.cards.customTest.title}</h4>
              <p>{whyAutomation.cards.customTest.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr8"></span>
              <h4>{whyAutomation.cards.customService.title}</h4>
              <p>{whyAutomation.cards.customService.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr9"></span>
              <h4>{whyAutomation.cards.bestSolution.title}</h4>
              <p>{whyAutomation.cards.bestSolution.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr10"></span>
              <h4>{whyAutomation.cards.effortless.title}</h4>
              <p>{whyAutomation.cards.effortless.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr11"></span>
              <h4>{whyAutomation.cards.professional.title}</h4>
              <p>{whyAutomation.cards.professional.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr12"></span>
              <h4>{whyAutomation.cards.competitive.title}</h4>
              <p>{whyAutomation.cards.competitive.description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>);
}

export default AutomationServiceWhy;