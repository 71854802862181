import React, { createContext, useContext, useState } from 'react';

const ActiveLinkContext = createContext();

export const useActiveLink = () => {
    return useContext(ActiveLinkContext);
};

export const ActiveLinkProvider = ({ children }) => {
    const [activeLink, setActiveLink] = useState('Home');

    const updateActiveLink = (link) => {
        setActiveLink(link);
    };

    return (
        <ActiveLinkContext.Provider value={{ activeLink, updateActiveLink }}>
            {children}
        </ActiveLinkContext.Provider>
    );
};
