export const contactUsContent = {
    heading: "Looking for Expert Software Testers?",
    description: "We're here to help. Please fill out the form below, and our team will get in touch with you shortly.",
    aboutCompany: [
        // {
        //     country: "USA",
        //     address: "14720 E., 44th St. S. Independence, MO, USA 64055",
        //     phone: "+1-000-000-0000",
        //     email: "info@missco.com"
        // },
        {
            country: "INDIA",
            address: "Tulsi Residency, Infront of Siyaram Aashram Deeg Road, Goverdhan, Mathura Uttar Pradesh - (281502)",
            phone: "+91-000-000-0000",
            email: "info@miisco.com"
        },
    ]
};
