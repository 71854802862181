import React from "react";
import '../../Css/Loader.css'
function Loader() {
    return (<>
        <div className="loader-div">


            <span class="loader"></span>
        </div>
    </>)
}

export default Loader;