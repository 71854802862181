import React, { useEffect } from "react";
import { content } from "../../Content/HomeContent";
import { useState } from "react";
import { constants } from "../../config/Config";
import { testimonialContent } from "../../Content/TestimonialContent";

function HomeFeedback() {

    //Animation Effect On Load on Content
    const [animationLeft, setAnimationLeft] = useState("");
    const [animationRight, setAnimationRight] = useState("");
    const [visible, setVisible] = useState("hidden")
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        function triggerOnScroll() {
            setAnimationLeft("animate__fadeInLeft");
            setAnimationRight("animate__fadeInRight");
            setIsAnimated(true);
            setVisible("") // Set isAnimated to true when the animation is triggered
        }

        window.addEventListener("scroll", function () {
            const scrollPosition = window.scrollY; // Vertical scroll position
            if (scrollPosition > 4800 && !isAnimated) {
                triggerOnScroll();
            }
        });
    }, [isAnimated]);
    //..

    const { testimonial } = content;
    const { customers } = testimonialContent
    return (<>
        <section className="feedback top-shadow">
            <div className={`container ${visible}`}>
                <div className="row justify-content-between align-items-center">
                    <div className={`col-lg-6 col-xl-5 animate__animated ${animationLeft} `}>
                        <h2>{testimonial.heading}</h2>
                        <p>{testimonial.description}</p>
                        <a href="/reviews" className="btn gradient-btn">More Reviews</a>
                    </div>
                    <div className={`col-lg-6 animate__animated ${animationRight} `}>
                        <div className="custom-card project-card home-card-shadow">
                            <div className="card-body">
                                <div className="img-div"><img src={`${constants.imagePath}/${customers[0].profile}`} alt={customers[0].name} /></div>
                                <div className="content">
                                    <h4>{customers[0].name}</h4>
                                    <p>{`${customers[0].review.split(' ').slice(0, 19).join(' ')}...`}</p>
                                </div>
                            </div>
                        </div>
                        <div className="custom-card margin-left project-card home-card-shadow">
                            <div className="card-body">
                                <div className="img-div"><img src={`${constants.imagePath}/${customers[1].profile}`} alt={customers[1].name} /></div>
                                <div className="content">
                                    <h4>{customers[1].name}</h4>
                                    <p>{`${customers[1].review.split(' ').slice(0, 20).join(' ')}...`}</p>
                                </div>
                            </div>
                        </div>
                        <div className="custom-card project-card home-card-shadow">
                            <div className="card-body">
                                <div className="img-div"><img src={`${constants.imagePath}/${customers[2].profile}`} alt={customers[2].name} /></div>
                                <div className="content">
                                    <h4>{customers[2].name}</h4>
                                    <p>{`${customers[2].review.split(' ').slice(0, 24).join(' ')}...`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)

}


export default HomeFeedback;