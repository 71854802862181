import React from "react";
import Form from '../../Common/Form'
import { mobileContent } from '../../../Content/MoblieContent'
import MyHubSpotForm from "../../Common/MyHubSpotForm";

function MobileServiceContact() {

  const { contact } = mobileContent;
  return (<>
    <section className="home-contact">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-6 col-xl-5">
            <h2>{contact.heading}</h2>
            <p>{contact.description}</p>
          </div>
          <div className="col-lg-6">
            <MyHubSpotForm />
          </div>
        </div>
      </div>
    </section>

  </>);
}

export default MobileServiceContact;