
import React, { useEffect, useState } from "react";

function ThankYouPage() {

    //Animation Effect On Load on Content
    const [animationUp, setAnimationUp] = useState("");
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        function triggerOnScroll() {
            setAnimationUp("animate__fadeInUp")
            setIsAnimated(true);
        }

        window.addEventListener("scroll", function () {
            const scrollPosition = window.scrollY; // Vertical scroll position
            if (scrollPosition > 0 && !isAnimated) {
                triggerOnScroll();
            }
        });
    }, [isAnimated]);
    //..

    return (<>
        <section className="home-services top-shadow" >
            <div className={`container`}>
                <div className={`animate__animated ${animationUp}`}>
                    <h2 style={{ color: 'white', fontWeight: 800, fontSize: '3rem' }}>Thank you For Your Interest in Miisco. <br /> Our Team will get in touch with you <br /> Within 24 hours.</h2>
                </div>
            </div>
        </section >
    </>)

}


export default ThankYouPage;