export const aboutContent = {
    heading: "About Miisco: Leading Software Testing Company",
    description: {
        One: "Miisco has been a trusted QA services company for over a decade, specializing in quality engineering services. We are dedicated to helping clients ensure the reliability and security of their software applications.",
        Two: "Our team of experienced professionals excels in a wide range of software testing services, including functional, compatibility, and security testing. We seamlessly integrate with your development teams, delivering the highest-quality results.",
        Three: "We take pride in serving 150+ global clients, providing testing expertise for websites, mobile apps, desktop software, server-side systems, and various devices. With 10+ years of experience, we have tested 250+ mobile apps and 350+ websites, guaranteeing top-notch quality and reliability.",
    },
    cards: [
        {
            title: "10+",
            describe: "Years in QA Business",
        },
        {
            title: "150+",
            describe: "Clients Worldwide",
        },
        {
            title: "50+",
            describe: "Mobiles Apps Tested",
        },
        {
            title: "350+",
            describe: "Sites Tested & Automated",
        },
        {
            title: "100+",
            describe: "Bot Developed",
        },
        {
            title: "75+",
            describe: "Business Automated",
        },
    ],
    vision: " Delivering Secure and Bug-Free Software Experiences",
    mission: " Crafting User-Friendly Software with Precision Through Quality Assurance and Testing Services",
    whyMiisco: {
        heading: "Why Choose Miisco?",
        description: "Discover the reasons to partner with Miisco for your software testing needs. Client satisfaction is our top priority.",
        domain: {
            title: "Multi-Domain Expertise",
            description: "Our extensive experience spans various domains, industries, and software types, allowing us to adapt to diverse project requirements.",
        },
        protection: {
            title: "Intellectual Property Rights",
            description: "We prioritize the protection of intellectual property rights, ensuring that creators' rights are preserved without conflicts.",
        },
        experience: {
            title: "Experienced Engineering Team",
            description: "Our skilled team delivers cost-effective, high-quality software testing solutions based on years of experience.",
        },
        report: {
            title: "Efficient Reporting Structure",
            description: "Miisco's organizational efficiency and clear reporting structure eliminate redundancy, ensuring timely, high-quality solutions.",
        },
        path: {
            title: "Optimized Hierarchy and Escalation Path",
            description: "Our defined hierarchy and structured escalation path follow best practices in quality assurance, delivering performance-driven results.",
        },
        support: {
            title: "24/7 Client Support",
            description: "We offer 24/7 client support to provide timely assistance and peace of mind for your software testing needs.",
        },
    },
    contact: {
        heading: "Contact Miisco for Your QA Requirements",
        describe: "Let us help you deliver quick and high-quality solutions to your clients. Fill in the provided information, and our team will promptly get in touch with you.",
    },
}
