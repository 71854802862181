export const coreTeamContent = {

    heading: "Our Core Team Members",
    description: "",

    members: [
        {
            profile: "shobhit.jpg",
            name: "Shobhit Maheshwari",
            position: "Founder/CFO",
            description: "driving financial growth, and maintaining the company's financial health.",
        },
        {
            profile: "mohit.jpg",
            name: "Mohit Maheshwari",
            position: "Founder/CEO",
            description: "Committed to driving innovation, growth, and fostering a culture of excellence.",
        },

        {
            profile: "vishal.png",
            name: "Vishal Maheshwari",
            position: "Co-Founder/CMO",
            description: "Lead cutting-edge development, ensuring that innovation in it's industry. ",
        },
        {
            profile: "gaurav.jpg",
            name: "Gaurav Sharma",
            position: "Senior QA and Automation Engineer",
            description: "primary responsibility is to oversee and implement top-tier quality assurance processes, ensuring the reliability and efficiency of software solutions.",
        },
        {
            profile: "abhishek.jpg",
            name: "Abhishek",
            position: "Lead Innovation Developer",
            description: "Adept at both front-end and backend development, ensuring a comprehensive approach to creating seamless and high-quality applications.",
        },
        // {
        //     profile: "shreyansh.jpg",
        //     name: "Shreyansh Agrawal",
        //     position: "Front End Engineer",
        //     description: "Specializing in front-end, they strive for excellence in crafting visually appealing and user-friendly interfaces to elevate the overall user experience.",
        // },

        {
            profile: "nitya.jpg",
            name: "Nitya Maheshwari",
            position: "QA Automation Engineer",
            description: "Providing high quality QA & Automation services is their main goal.",
        },
        {
            profile: "riya.png",
            name: "Riya Rathi",
            position: "Backend Engineer",
            description: "Excelling in backend, prioritizing high-quality development and performance.",
        },
        {
            profile: "saymaynew.jpg",
            name: "Soumy Maheshwari",
            position: "Web Automation Engineer",
            description: "Providing QA & Automation services is their main goal."
        },
        {
            profile: "lalit2.jpg",
            name: "Lalit Kishor Sharma",
            position: "Process Automation Engineer",
            description: "Designing, Optimising and Implementing automated workflow solutions in Engineering."
        }


    ],

}