import React, { useEffect } from "react";
import Form from "../Common/Form";
import { content } from "../../Content/HomeContent";
import { useState } from "react";
import MyHubSpotForm from "../Common/MyHubSpotForm";

function HomeContact() {

    //Animation Effect On Load on Content
    const [animationLeft, setAnimationLeft] = useState("");
    const [animationRight, setAnimationRight] = useState("");
    const [visible, setVisible] = useState("hidden")
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        function triggerOnScroll() {
            setAnimationLeft("animate__fadeInLeft");
            setAnimationRight("animate__fadeInRight");
            setIsAnimated(true);
            setVisible("") // Set isAnimated to true when the animation is triggered
        }

        window.addEventListener("scroll", function () {
            const scrollPosition = window.scrollY; // Vertical scroll position
            if (scrollPosition > 4000 && !isAnimated) {
                triggerOnScroll();
            }
        });
    }, [isAnimated]);
    //..

    const { contact } = content;
    return (<>
        <section className="home-contact">
            <div className="container">
                <div className={`row align-items-center justify-content-between ${visible}`}>
                    <div className={`col-lg-6 col-xl-5 animate__animated ${animationLeft}`}>
                        <h2>{contact.heading}</h2>
                        <p>{contact.description}</p>
                    </div>
                    <div className={`col-lg-6 ${animationRight}`}>
                        {/* <Form /> */}
                        <MyHubSpotForm />
                    </div>
                </div>
            </div>
        </section>
    </>)

}


export default HomeContact;