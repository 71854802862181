import React from "react";
import ContactBanner from "./ContactBanner";
import ContactForm from "./ContactForm";
import QaClient from "../Common/QaClient";


function ContactPage() {
    return (<>
        <ContactBanner />
        <ContactForm />
        <QaClient />
    </>);
}

export default ContactPage;