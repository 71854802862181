export const apiContent = {
    descriptionOne: {
        heading: "API Testing Services for Web and Mobile Applications",
        paragraph: "API testing plays a critical role in the integration of web and mobile applications. At Miisco, our API testing services ensure secure and functional data access, saving valuable time and resources. Partner with us for:",
        bullets: [
            "Early detection and prevention of functional defects during development.",
            "Seamless integration of software components, even under heavy loads.",
            "Enhanced security by preventing unauthorized access to sensitive data.",
            "Comprehensive testing for both SOAP and RESTful web APIs.",
            "Optimized performance, even during peak traffic hours.",
            "Identification and resolution of errors for improved reliability.",
            "Efficient communication between SOAP APIs and other software features.",
        ],
    },

    descriptionTwo: {
        heading: "Benefits of Our API Testing Services",
        paragraph1: "Miisco delivers top-notch API testing services to ensure your software meets market demands. Our experienced testers provide high-quality results, offering numerous benefits:",
        paragraph2: "Our API testing company specializes in:",
        bullets: [
            "Proactive error prevention and correction, avoiding major issues.",
            "Cost-effective testing with minimal coding requirements.",
            "Time-saving compared to other testing processes.",
            "Comprehensive testing, including GUI testing for seamless integration.",
            "Language-independent testing for broader compatibility.",
            "Improved application security with reduced vulnerabilities.",
        ],
    },

    whyAPI: {
        heading: "Why Choose Miisco for API Testing?",
        cards: {
            reliable: {
                title: "Reliable API Testing Expertise",
                description: "Miisco's experienced team excels in API testing, including Agile, DevOps, REST, and SOAP, ensuring optimal results for mobile and web API testing.",
            },

            apiPerformance: {
                title: "API Performance Testing",
                description: "Our testers create test suites to prepare your APIs for heavy 24/7 performance, ensuring reliability.",
            },

            businessFocus: {
                title: "Business-Focused API Testing",
                description: "We align our testing services with your business goals, ensuring software success.",
            },

            competitivePrice: {
                title: "Competitive Pricing",
                description: "Miisco delivers premium API testing services at competitive prices for all platforms.",
            },

            efficientApi: {
                title: "Efficient API Testing Service",
                description: "We utilize the best testing tools and advanced technology to provide a robust API testing service.",
            },

            customApi: {
                title: "Customized API Testing",
                description: "Tailored testing services match your unique business needs, leveraging various API testing tools.",
            },
        },
    },
    contact: {
        heading: "Contact Miisco's API Testing Experts",
        description: "Transform your business vision into reality with Miisco's API testing services. Stand out from competitors by delivering a seamless user experience. Contact us to boost testing efficiency with our expertise.",
    },

    testingProcess: {
        heading: "Our API Testing Process",
        description: "Miisco follows a systematic process to deliver software testing projects on time. Our process includes several key phases:",
        cards: {
            analysis: "Requirement Analysis",
            planning: "Test Planning",
            development: "Test Case Development",
            environment: "Test Environment Setup",
            execution: "Test Execution",
            closure: "Test Closure",
        },
    },
    // blog: {
    //     one: {
    //         title: "MySQL Workbench Installation and MySQL Server Connection",
    //         description: "Learn how to install MySQL Workbench and set up a MySQL server. This guide covers essential SQL terms and helps you get started with database management.",
    //     },
    //     two: {
    //         title: "Who Performs Mobile App Testing?",
    //         description: "Discover how Miisco's expert mobile app testers can enhance your app's user experience and deliver impressive results.",
    //     },
    //     three: {
    //         title: "Useful MySQL Queries for Quality Assurance",
    //         description: "Explore essential MySQL queries for quality assurance, covering key database operations to ensure data integrity.",
    //     },
    // },
}