import React from "react";
import ManualServiceDescriptionOne from "./ManualServiceDescriptionOne";
import ManualServiceWhy from "./ManualServiceWhy";
import ManualServiceContact from "./ManualServiceContact";
import ManualTesting from "./ManualTesting";
import ManualServiceDescriptionTwo from "./ManualServiceDescriptionTwo";
import ManualServiceBanner from "./ManualServiceBanner";
import ManualServiceBlog from "./ManualServiceBlog";
import QaClient from "../../Common/QaClient";


function ManualServicePage() {
    return (<>
        <ManualServiceBanner />
        <ManualServiceDescriptionOne />
        <ManualServiceDescriptionTwo />
        <ManualServiceWhy />
        <ManualServiceContact />
        <ManualTesting />
        {/* <ManualServiceBlog /> */}
        <QaClient />
    </>);
}

export default ManualServicePage;