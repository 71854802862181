import React from "react";


function AutomationServiceBanner() {
  return (<>
    <section className="inner-banner container">
      <div>
        <h1>Automation Testing Services</h1>
        <p>
          <span style={{ opacity: 0.8 }}>Services</span> &nbsp;-&nbsp; Test Automation Services
        </p>
      </div>
    </section>

  </>);
}

export default AutomationServiceBanner;