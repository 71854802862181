import React from 'react'


function ProjectBanner() {
    return (<>
        <section className="inner-banner container">
            <div>
                <h1>Projects</h1>
                <p>
                    <span style={{ opacity: 0.8 }}>Company</span> &nbsp;-&nbsp; Projects
                </p>
            </div>
        </section>

    </>);
}

export default ProjectBanner;