import React from "react";
import { mobileContent } from '../../../Content/MoblieContent'

function MobileApplicationTesting() {
  const { testingProcess } = mobileContent;
  return (<>
    <section className="light-bg software-testing">
      <div className="container">
        <h2>{testingProcess.heading}</h2>
        <p>{testingProcess.description}</p>
        <div className="row">
          <div className="col-xxl-2 col-lg-4 col-6">
            <div className="row flex-column">
              <div className="round-card">
                <span className="sr1"></span>
              </div>
              <h5>{testingProcess.cards.analysis}</h5>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-4 col-6">
            <div className="row flex-column flex-xxl-column-reverse">
              <div className="round-card">
                <span className="sr2"></span>
              </div>
              <h5>{testingProcess.cards.planning}</h5>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-4 col-6">
            <div className="row flex-column">
              <div className="round-card">
                <span className="sr3"></span>
              </div>
              <h5>{testingProcess.cards.development}</h5>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-4 col-6">
            <div className="row flex-column flex-xxl-column-reverse">
              <div className="round-card">
                <span className="sr4"></span>
              </div>
              <h5>{testingProcess.cards.environment}</h5>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-4 col-6">
            <div className="row flex-column">
              <div className="round-card">
                <span className="sr5"></span>
              </div>
              <h5>{testingProcess.cards.execution}</h5>
            </div>
          </div>
          <div className="col-xxl-2 col-lg-4 col-6">
            <div className="row flex-column flex-xxl-column-reverse">
              <div className="round-card last">
                <span className="sr6"></span>
              </div>
              <h5>{testingProcess.cards.closure}</h5>
            </div>
          </div>
        </div>
      </div>
    </section>


  </>);
}

export default MobileApplicationTesting;