import React from 'react';
import Form from '../../Common/Form'
import { aboutContent } from '../../../Content/AboutContent';
import MyHubSpotForm from '../../Common/MyHubSpotForm';

function AboutContact() {
  const { contact } = aboutContent;
  return (
    <>
      <section className="light-bg inner-contact">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-6 col-xl-5">
              <h2>{contact.heading}</h2>
              <p>{contact.describe}</p>
            </div>
            <div className='col-lg-6'>
              <MyHubSpotForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}


export default AboutContact;