export const testimonialContent = {

    heading: "Miisco’s All Over Satisfied Customers",
    description: "Miisco offers high quality and timely QA and testing services to its client. We are established in 2011 and aiming to help organizations from various sectors such as automobile, retail, IT, healthcare, banking and various others.",

    customers: [
        {
            profile: "omer.png",
            name: "Omer Akben",
            review: "MIISCO LLP exceeded expectations in providing QA and automation services. Their professionalism, deep expertise, and effective communication ensured a seamless collaboration. The team's project management skills, commitment to deadlines, and rigorous QA processes contributed to the overall success of our project. MIISCO LLP demonstrated flexibility, adapting to changes and showcasing a collaborative spirit. Their post-implementation support was commendable, addressing issues promptly. I highly recommend MIISCO LLP for their commitment to quality and client satisfaction. We look forward to future collaborations.",
        },
        {
            profile: "leesa.png",
            name: "Leesa Mattresses (USA)",
            review: "Our experience with the freelancer on the UI automation project for mattresses and e-commerce was exceptional. Their expertise in navigating the intricacies of UI automation was evident, leading to a successful and timely project completion. The freelancer's keen understanding of the unique challenges in the mattress and e-commerce domain was impressive. They demonstrated a high level of professionalism, effective communication, and a proactive approach to problem-solving. The client is not only extremely happy with the delivered work but also appreciates the freelancer's dedication to ensuring the project's success. We highly recommend their services and look forward to potential future collaborations.",
        },
        {
            profile: "oliver.png",
            name: "Oliver Jacobs (Australia)",
            review: "My company had some web automation projects needed to be developed and our dev team had little experience working with Selenium. Mohit is certainly an expert. He actively contributed to our standups, was very accessible; offered suggestions and always asked very good questions that would we didn't think about previously . He was very productive, always delivered. He worked with and helped other developers get going in Selenium. He regularly thought \"outside the box\" when helping our other developers getting around the many nuances found in working with Selenium and assisted in brainstorming different solutions when conceptualizing new development and the engineering behind it. Mohit is talented in other other areas besides Selenium as well and he will be the first engineer we call when we have more work for him. Communication is very important to me and although English is not Mohit's Primary language, Mohit speaks English fluently and we can communicate with Mohit very well. Needless to say, Highly recommended",
        },
        {
            profile: "kevin.png",
            name: "Kevin Mccafferey (USA)",
            review: "All around great experience. His knowledge helped me figure out even better what I needed to have done and what I could have done later, which is great for sticking to budget.",
        },

        // {
        //     profile: "",
        //     name: "Zhandos Alpys",
        //     review: "They completed everything that was required, in particular the communications were very fast, all comments were also quickly corrected. We are very satisfied and recommend Miisco to everyone.",
        // },
        // {
        //     profile: "",
        //     name: "Wesley Brar",
        //     review: "We achieved more than 120% growth in our wallpaper retail business just because you guys put the right elements in place on our website. Thank you very much for your hard work.",
        // },
        // {
        //     profile: "",
        //     name: "Rena Sumrall",
        //     review: "Excellent professional to work with. Very knowledgeable, can act independently with only minimal guidance, creative, communicates extremely well. Will definitely be working with him again.",
        // },
        // {
        //     profile: "",
        //     name: "Matthew",
        //     review: "It was a pleasure doing business with the experts at 'Miisco'. Besides a sound understanding of technology, the team understood the value of commitment and proactively sharing their experiences and best practices.",
        // },
        // {
        //     profile: "",
        //     name: "Daniel Abrahamsen",
        //     review: "Great work on the Android version of our app. Thanks and we look forward to working with you on future projects!",
        // },
        // {
        //     profile: "",
        //     name: "Marshall Glenn",
        //     review: "Very satisfied with the work done. Everything coming together for the show - really great work you guys have done.",
        // },

    ],
    contact: {
        heading: "Share Your QA Requirement With Us!!",
        description: "Our Miisco solution experts will get back to you for a free QA consultation.",
    },

    qaClient: "Our Testing Experts are Certified",
}