import React from "react";


function QaConsultBanner() {
  return (<>
    <section className="inner-banner container">
      <div>
        <h1>QA Consultation</h1>
        <p>
          <span style={{ opacity: 0.8 }}>Home</span> &nbsp;-&nbsp; QA Consultation
        </p>
      </div>
    </section>
  </>)
}


export default QaConsultBanner;