import React from 'react'
import CoreTeamFeedback from './CoreTeamFeedback';


function CoreTeamPage() {
    return (<>
        <body className="page-loaded">

            <CoreTeamFeedback />


        </body>

    </>);
}

export default CoreTeamPage;