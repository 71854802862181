import React from "react";
import Form from '../../Common/Form'
import MyHubSpotForm from "../../Common/MyHubSpotForm";

function CompatibilityServiceContact() {
  return (<>
    <section className="home-contact">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-6 col-xl-5">
            <h2>Get In Touch</h2>
            <p>Missco's professional compatibility testing service includes various devices, OS, peripherals, platforms, and hardware. When you're ready, take the next step and contact our testing team for further information.</p>
          </div>
          <div className="col-lg-6">
            <MyHubSpotForm />
          </div>
        </div>
      </div>
    </section>

  </>);
}

export default CompatibilityServiceContact;