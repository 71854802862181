import React, { useEffect, useState } from "react";
import { content } from "../../Content/HomeContent";
import { constants } from "../../config/Config";


function HomeWhyChoose() {

    //Animation Effect On Load on Content
    const [animationLeft, setAnimationLeft] = useState("");
    const [animationRight, setAnimationRight] = useState("");
    const [visible, setVisible] = useState("hidden")
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        function triggerOnScroll() {
            setAnimationLeft("animate__fadeInLeft");
            setAnimationRight("animate__fadeInRight");
            setIsAnimated(true);
            setVisible("") // Set isAnimated to true when the animation is triggered
        }

        window.addEventListener("scroll", function () {
            const scrollPosition = window.scrollY; // Vertical scroll position
            if (scrollPosition > 1500 && !isAnimated) {
                triggerOnScroll();
            }
        });
    }, [isAnimated]);
    //..

    const { whyMiisco } = content;
    return (<>

        <section className="light-bg why-choose">
            <div className="container">
                <div className={`row align-items-center ${visible}`}>
                    <div className={`col-lg-7 animate__animated ${animationLeft}`}>
                        <img src={`${constants.imagePath}/home-why-us.webp`} alt="Why Miisco for Software Testing" loading="lazy" width="744px" height="600px" />
                    </div>
                    <div className={`col-lg-5 animate__animated ${animationRight}`}>
                        <h2>{whyMiisco.heading}</h2>
                        <p>{whyMiisco.description}</p>
                        <ul className="inr-listing">
                            {whyMiisco.bullets.map((value, index) => {
                                return <li key={index}>{value}</li>
                            })}
                        </ul><a href="/contact" className="btn gradient-btn">{whyMiisco.buttonText}</a>
                    </div>
                </div>
            </div>
        </section>
    </>)

}


export default HomeWhyChoose;