export const crossBrowserContent = {
    descriptionOne: {
        heading: "Cross Browser Testing Services",
        paragraph1: "Cross-browser testing is an essential requirement for every website in today's competitive market. Miisco offers powerful and user-friendly cross-browser testing services to enhance your products.",
        paragraph2: "Our cross-browser testing company can:",
        bullets: [
            "Test your application across popular and up-to-date browsers and devices.",
            "Ensure smooth operation, optimal rendering, reliability, security, and integration with other systems.",
            "Enhance the quality and user adoption of your app/website.",
            "Create a seamless, device-independent user experience.",
            "Identify layout issues, errors, and inconsistencies between browser versions.",
            "Conduct multiple automated processes simultaneously.",
        ],
    },

    descriptionTwo: {
        heading: "Why You Need Cross Browser Testing",
        paragraph1: "Cross-browser testing can be time-consuming and challenging, especially when testing across multiple browsers. Miisco simplifies the process by creating customized testing plans for your website.",
        paragraph2: "Cross-browser testing is essential because it:",
        bullets: [
            "Offers access to thousands of real devices on the cloud for comprehensive testing.",
            "Provides scalable compatibility testing without infrastructure setup.",
            "Identifies UI and functional issues, such as inaccurate information and redirects.",
            "Delivers instant cross-compatibility test results, reducing build time.",
            "Tests performance metrics like compatibility and load testing in live environments for accuracy.",
            "Supports both manual and automated testing for faster releases.",
        ],
    },

    whyCrossBrowser: {
        heading: "Why Choose Miisco for Cross Browser Testing",
        cards: {
            efficacy: {
                title: "Efficiency at Scale",
                description: "Our cloud-based cross-browser tests are perfect for globally distributed teams and offer scalability.",
            },

            report: {
                title: "Comprehensive Reporting",
                description: "Miisco provides detailed reports on web application performance, including response times, loading times, and error detection.",
            },

            seeWhat: {
                title: "See What Users See",
                description: "Cross-browser testing helps you prepare and test your website on multiple browsers before going live.",
            },

            fool: {
                title: "Foolproof Website Testing",
                description: "We assess user-friendliness, functionality, security, and load handling to ensure a reliable website.",
            },

            cycleTest: {
                title: "Full-Cycle Testing",
                description: "Miisco offers end-to-end testing services, from test plan development to execution, for maximum test coverage and quality.",
            },

            industry: {
                title: "Industry-Specific Expertise",
                description: "Our expertise spans multiple industries, including healthcare, retail, finance, IT, and more.",
            },
        },
    },
    contact: {
        heading: "Contact Miisco for Cross Browser Testing",
        description: "With numerous browsers, platforms, and operating systems available, cross-browser testing is crucial for software development. Contact us to expedite releases, detect bugs early, and improve developer productivity.",
    },

    testingProcess: {
        heading: "Our Software Testing Process",
        description: "Miisco follows a systematic process to deliver software testing projects on time. Our process includes phases such as:",
        cards: {
            analysis: "Requirement Analysis",
            planning: "Test Planning",
            development: "Test Case Development",
            environment: "Test Environment Setup",
            execution: "Test Execution",
            closure: "Test Closure",
        },
    },
    blog: {
        one: {
            title: "Mobile App Testing: Virtual Device Vs. Real Device",
            description: "Explore the differences between testing mobile apps on virtual devices and real devices, and choose the right approach for your needs.",
        },
        two: {
            title: "Mobile App Testing: Process & Benefits",
            description: "Learn about the challenges of mobile app testing and how it can ensure your app functions seamlessly across a wide range of devices.",
        },
        three: {
            title: "Difference Between Mobile App & Web App Testing",
            description: "Gain insights into the features and benefits of mobile app testing versus web app testing, based on our extensive QA testing experience.",
        },
    },
};
