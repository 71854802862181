export const unitContent = {
    descriptionOne: {
        heading: "Unit Testing Services",
        paragraph1: "Unit testing services focus on testing small software components independently, without relying on external systems. It's a crucial part of early-stage development to detect potential defects. Miisco's unit testing company offers the following services:",
        bullets: [
            "Reviewing project requirements and planning methodology",
            "Performing application unit testing to ensure code functionality",
            "Detecting early code faults",
            "Functional testing to ensure optimal feature functionality",
            "Structural testing to examine system architecture",
            "Executing test cases based on system requirements",
            "White-box testing to inspect internal software structures",
            "Software code optimization to enhance efficiency",
        ],
    },

    descriptionTwo: {
        heading: "Why You Need Unit Testing Services",
        paragraph: "Unit testing and white-box testing in the development phase can save your company significant costs in later stages. Miisco's unit testing services help you:",
        bullets: [
            "Deliver high-quality code for further testing phases",
            "Save money by identifying and fixing issues early",
            "Detect errors and glitches at the earliest stages",
            "Ensure comprehensive testing of independent paths",
            "Verify decisions based on their values",
            "Test all loops at their boundaries",
        ],
    },

    whyUnit: {
        heading: "Why Choose Miisco for Unit Testing Services",
        cards: {
            earlyError: {
                title: "Early Error Detection",
                description: "Unit testing and white-box testing uncover and resolve issues before they become costly problems for your digital presence.",
            },

            deliverOnTime: {
                title: "On-Time Delivery",
                description: "Miisco's team values deadlines and strives to deliver premium testing services punctually.",
            },

            professional: {
                title: "Professional Approach",
                description: "Our engineers and testers work with the latest languages, frameworks, and testing tools to provide top-notch unit testing services.",
            },

            saveMoney: {
                title: "Cost Savings",
                description: "Late-stage problem discovery can cost companies tens of thousands of dollars. Identifying errors in early development stages reduces expenses.",
            },

            expertTest: {
                title: "Expert Testing Team",
                description: "Miisco's highly skilled team tailors testing services to your specific requirements, ensuring professionalism.",
            },

            reliable: {
                title: "Reliable and Transparent Service",
                description: "With experience across various industries, we consistently deliver reliable testing services after each release.",
            },
        },
    },

    contact: {
        heading: "Contact the Miisco Team",
        description: "Looking for a professional team to manage your mobile app and website testing, including unit testing and white-box testing? Contact our experienced testers and engineers to start your custom unit testing service.",
    },

    testingProcess: {
        heading: "Our Software Testing Process",
        description: "Miisco follows a systematic process to deliver software testing projects within set deadlines. Our process includes several phases:",
        cards: {
            analysis: "Requirement Analysis",
            planning: "Test Planning",
            development: "Test Case Development",
            environment: "Test Environment Setup",
            execution: "Test Execution",
            closure: "Test Closure",
        },
    },

    blog: {
        one: {
            title: "Manual Testing: Types, Pros, Cons & How to Perform",
            description: "Explore the realm of manual testing, its pros, cons, and effective execution in this comprehensive guide.",
        },
        two: {
            title: "Manual Testing - Usage and Best Practices",
            description: "Optimize your software testing process and deliver high-quality products with Miisco's expert team specializing in manual and automated testing.",
        },
        three: {
            title: "Most Useful MySQL Queries for Quality Assurance",
            description: "Discover essential MySQL queries for quality assurance in this informative article.",
        },
    },
};
