import React from 'react'

import { coreTeamContent } from '../../../Content/CoreTeamContent';
import { constants } from '../../../config/Config'


function CoreTeamFeedback() {

    const { heading, description, members } = coreTeamContent;
    return (<>

        <section className="feedback inner-feedback">
            <div className="container">
                <h2>{heading}</h2>
                <p className="title-text">{description}</p>
                <div className="profile-card-warpper">
                    {members.map((value, index) => {
                        return <div className="col-lg-6" key={index}>
                            <div class="profile-card">
                                <img src={`${constants.profileImagepath}/${value.profile}`} alt="Worker 1" class="profile-image" />
                                <div class="profile-name">{value.name}</div>
                                <div class="profile-position">{value.position}</div>
                                <p class="profile-description">{value.description}</p>
                            </div>
                            {/* <div className="custom-card">
                                <div className="card-body">
                                    <div className="img-div">
                                        <img src={`${constants.imagePath}/Aron-Kansal.webp`} alt="Aron Kansal Review" />
                                    </div>
                                    <div className="content">
                                        <h4>{value.name}</h4>
                                        <p>{value.review}</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    })};

                </div>
            </div>
        </section>
    </>);
}

export default CoreTeamFeedback;