import React from "react";
import { securityContent } from "../../../Content/SecurityContent";


function SecurityServiceWhy() {

  const { whySecurity } = securityContent;
  return (<>
    <section className="why-we">
      <div className="container">
        <h2>{whySecurity.heading}</h2>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr28"></span>
              <h4>{whySecurity.cards.extensive.title}</h4>
              <p>{whySecurity.cards.extensive.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr29"></span>
              <h4>{whySecurity.cards.comprehensive.title}</h4>
              <p>{whySecurity.cards.comprehensive.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr30"></span>
              <h4>{whySecurity.cards.industries.title}</h4>
              <p>{whySecurity.cards.industries.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr2"></span>
              <h4>{whySecurity.cards.skilledTeam.title}</h4>
              <p>{whySecurity.cards.skilledTeam.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr31"></span>
              <h4>{whySecurity.cards.responsiveSupport.title}</h4>
              <p>{whySecurity.cards.responsiveSupport.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr32"></span>
              <h4>{whySecurity.cards.costEffective.title}</h4>
              <p>{whySecurity.cards.costEffective.description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>);
}

export default SecurityServiceWhy;