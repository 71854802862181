import React from 'react'
import ProjectWorks from './ProjectWorks';
import ProjectBanner from './ProjectBanner';
import ProjectContact from './ProjectContact';
import QaClient from '../Common/QaClient';


function ProjectPage() {
    return (<>
        <body className="page-loaded">

            <ProjectBanner />
            <ProjectWorks />
            <ProjectContact />
            < QaClient />


        </body>

    </>);
}

export default ProjectPage;