import { developmentContent } from "../../../Content/DevelopmentContent"

export default function WebDevelopmentWhy() {
    const { whyWebApp } = developmentContent.WebDevelopment
    return (
        <>
        <section className="why-we">
      <div className="container">
        <h2>{whyWebApp.heading}</h2>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr13"></span>
              <h4>{whyWebApp.cards.serviceKnowledge.title}</h4>
              <p>{whyWebApp.cards.serviceKnowledge.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr14"></span>
              <h4>{whyWebApp.cards.optimalUsability.title}</h4>
              <p>{whyWebApp.cards.optimalUsability.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr4"></span>
              <h4>{whyWebApp.cards.earlyIssue.title}</h4>
              <p>{whyWebApp.cards.earlyIssue.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr15"></span>
              <h4>{whyWebApp.cards.performanceTesting.title}</h4>
              <p>{whyWebApp.cards.performanceTesting.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr2"></span>
              <h4>{whyWebApp.cards.expertTeam.title}</h4>
              <p>{whyWebApp.cards.expertTeam.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr16"></span>
              <h4>{whyWebApp.cards.onTimeDelivery.title}</h4>
              <p>{whyWebApp.cards.onTimeDelivery.description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
        </>
    )
}