import React from 'react';
import { aboutContent } from '../../../Content/AboutContent';

function AboutWhy() {

  const { whyMiisco } = aboutContent;
  return (
    <>
      <section className="about-why">
        <div className="container">
          <h2>{whyMiisco.heading}</h2>
          <p className="title-text">{whyMiisco.description}</p>
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="card">
                <span className="sr1"></span>
                <h4>{whyMiisco.domain.title}</h4>
                <p>{whyMiisco.domain.description}</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="card">
                <span className="sr2"></span>
                <h4>{whyMiisco.protection.title}</h4>
                <p>{whyMiisco.protection.description}</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="card">
                <span className="sr3"></span>
                <h4>{whyMiisco.experience.title}</h4>
                <p>{whyMiisco.experience.description}</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="card">
                <span className="sr4"></span>
                <h4>{whyMiisco.report.title}</h4>
                <p>{whyMiisco.report.description}</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="card">
                <span className="sr5"></span>
                <h4>{whyMiisco.path.title}</h4>
                <p>{whyMiisco.path.description}</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="card">
                <span className="sr6"></span>
                <h4>{whyMiisco.support.title}</h4>
                <p>{whyMiisco.support.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}


export default AboutWhy;