import React from 'react'

import { testimonialContent } from '../../../Content/TestimonialContent';
import TestimonalCard from './TestimonalCard';


function TestimonialFeedback() {

  const { heading, description, customers } = testimonialContent;
  return (<>

    <section className="feedback inner-feedback">
      <div className="container">
        <h2>{heading}</h2>
        <p className="title-text">{description}</p>
        <div className="row">

          {customers.map((value, index) => {
            return <>
              <TestimonalCard heading={value.name} content={value.review} profile={value.profile} />
            </>
          })};

        </div>
      </div>
    </section>
  </>);
}

export default TestimonialFeedback;