import React from "react";
import { content } from "../../Content/HomeContent";
// import healthcare from "../../../public/images/healthcare.png"


function HomeWorkSectors() {

  const sector = content.workSector;
  return (<>
    <section className="why-we">
      <div className="container">
        <h2>{sector.heading}</h2>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <div className="healthcare-icon"></div>
              <h4>{sector.healthCare.title}</h4>
              <p>{sector.healthCare.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr30"></span>
              <h4>{sector.realEstate.title}</h4>
              <p>{sector.realEstate.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <div className="logistics-icon"></div>
              <h4>{sector.logistics.title}</h4>
              <p>{sector.logistics.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr"></span>
              <h4>{sector.business.title}</h4>
              <p>{sector.business.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr5"></span>
              <h4>{sector.financial.title}</h4>
              <p>{sector.financial.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <div className="ecommerce-icon"></div>
              <h4>{sector.ecommerce.title}</h4>
              <p>{sector.ecommerce.description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>);
}

export default HomeWorkSectors;