import React from "react";
import CrossBrowserServiceDescriptionOne from "./CrossBrowserServiceDescriptionOne";
import CrossBrowserServiceWhy from "./CrossBrowserServiceWhy";
import CrossBrowserServiceContact from "./CrossBrowserServiceContact";
import CrossBrowserTesting from "./CrossBrowserTesting";
import CrossBrowserServiceDescriptionTwo from "./CrossBrowserServiceDescriptionTwo";
import CrossBrowserServiceBanner from "./CrossBrowserServiceBanner";
import CrossBrowserServiceBlog from "./CrossBrowserServiceBlog";
import QaClient from "../../Common/QaClient";


function CrossBrowserServicePage() {
    return (<>
        <CrossBrowserServiceBanner />
        <CrossBrowserServiceDescriptionOne />
        <CrossBrowserServiceDescriptionTwo />
        <CrossBrowserServiceWhy />
        <CrossBrowserServiceContact />
        <CrossBrowserTesting />
        {/* <CrossBrowserServiceBlog /> */}
        <QaClient />
    </>);
}

export default CrossBrowserServicePage;