import React from "react";
import { mobileContent } from "../../../Content/MoblieContent";


function MobileServiceWhy() {
  const { whyMobile } = mobileContent;
  return (<>

    <section className="why-we">
      <div className="container">
        <h2>{whyMobile.heading}</h2>
        <p className="title-text">{whyMobile.description}</p>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr1"></span>
              <h4>{whyMobile.cards.innovative.title}</h4>
              <p>{whyMobile.cards.innovative.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr2"></span>
              <h4>{whyMobile.cards.experts.title}</h4>
              <p>{whyMobile.cards.experts.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr3"></span>
              <h4>{whyMobile.cards.covering.title}</h4>
              <p>{whyMobile.cards.covering.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr4"></span>
              <h4>{whyMobile.cards.error.title}</h4>
              <p>{whyMobile.cards.error.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr5"></span>
              <h4>{whyMobile.cards.saving.title}</h4>
              <p>{whyMobile.cards.saving.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr6"></span>
              <h4>{whyMobile.cards.scale.title}</h4>
              <p>{whyMobile.cards.scale.description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>);
}

export default MobileServiceWhy;