import React, { useState } from 'react';
import { constants } from '../../../config/Config';

const TestimonalCard = ({ heading, content, profile }) => {
    const [showFullContent, setShowFullContent] = useState(false);

    const toggleContent = () => {
        setShowFullContent(!showFullContent);
    };

    const truncatedContent = showFullContent ? content : `${content.split(' ').slice(0, 24).join(' ')}...`;

    return (

        <div className="col-lg-6" >
            <div className="custom-card">
                <div className="card-body">
                    <div className="img-div">
                        <img src={`${constants.imagePath}/${profile}`} alt={`${heading}`} />
                    </div>
                    <div className="content testimonial-card">
                        <h4>{heading}</h4>
                        <p>{truncatedContent}</p>
                        {!showFullContent && (
                            <button
                                className='testimonial-read-more-button'
                                onClick={toggleContent}>Read More</button>
                        )}
                        {showFullContent && (<img
                            className='testimonial-down-collapse-btn'
                            src={`${constants.imagePath}/down-collapse.png`}
                            alt=''
                            onClick={toggleContent} />)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonalCard;
