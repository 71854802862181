export const qaContent = {
    descriptionOne: {
        heading: "QA Consulting Services",
        paragraph1: "Streamline your software quality assurance with Miisco's expert QA consulting. We identify and resolve QA problems, optimize workflows, and ensure flawless technology outcomes.",
        paragraph2: "Our QA consulting services can:",
        bullets: [
            "Analyze and improve your QA process for maximum efficiency",
            "Develop a risk mitigation plan for implementing a new QA strategy",
            "Refine your approach for rapid scaling",
            "Collaborate with stakeholders to design future-proof processes",
            "Implement and monitor improved processes for exceptional results",
            "Enhance reliability, security, and user experience",
        ],
    },

    descriptionTwo: {
        heading: "The Importance of QA Consulting",
        paragraph1: "Miisco helps organizations enhance quality assurance processes, automate tests, and create efficient QA workflows. Our services eliminate risks, accelerate time-to-market, and reduce ownership costs.",
        paragraph2: "QA consulting matters because:",
        bullets: [
            "Ensures software reliability and maintainability",
            "Identifies and prevents potential issues proactively",
            "Reduces unplanned task time by 22% when integrated into development",
            "Builds customer trust and a strong reputation",
            "Optimizes personalized onboarding campaigns",
            "Establishes and enforces development policies",
        ],
    },

    whyQa: {
        heading: "Why Choose Miisco for QA Consulting",
        cards: {
            tailored: {
                title: "Tailored Solutions",
                description: "Our QA consultants provide customized quality assurance consulting to meet your project's specific needs.",
            },

            expertise: {
                title: "World-Class QA Experts",
                description: "Identify and resolve QA process issues with the help of our experienced QA consultants.",
            },

            comprehensive: {
                title: "Comprehensive Process Improvement",
                description: "We optimize QA processes for products, projects, or entire organizations.",
            },

            monitoring: {
                title: "Monitoring and Continuous Improvement",
                description: "We continually enhance processes, measure QA metrics, and propose improvements.",
            },

            costEffective: {
                title: "Cost-Effective QA",
                description: "Reduce change management costs with our guidance on methodologies, technology, and testing types.",
            },

            dedicated: {
                title: "Dedicated Project Managers",
                description: "Our project managers ensure faster development cycles, better scope management, cost-effectiveness, and superior software quality.",
            },
        },
    },

    contact: {
        heading: "Contact Miisco for QA Consulting",
        description: "Looking for professional QA consulting services to optimize your mobile app and website quality? Contact Miisco's experienced testers and engineers to start your customized QA journey.",
    },

    testingTypes: {
        heading: "QA Testing Services",
        cards: {
            mobile: "Mobile Application Testing",
            automation: "Test Automation Services",
            manual: "Manual Testing Services",
            api: "API Testing Services",
            load: "Load and Performance Testing",
            security: "Security Testing Services",
            compatibility: "Compatibility Testing Services",
            unit: "Unit Test Services",
            crossBrowser: "Cross Browser Testing Services",
        },
    },

    blog: {
        one: {
            title: "Top MySQL Queries for Quality Assurance",
            description: "Explore the most useful MySQL queries for quality assurance and improve your database testing processes.",
        },
        two: {
            title: "MySQL Workbench Installation and Setup Guide",
            description: "Learn how to install and set up MySQL Workbench for efficient database management in this step-by-step guide.",
        },
        three: {
            title: "Penetration Testing with OWASP ZAP: A Comprehensive Guide",
            description: "Discover the features and modes of OWASP ZAP for effective penetration testing in this comprehensive guide.",
        },
    },
}
