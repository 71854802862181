import React from "react";


function CompatibilityServiceWhy() {
  return (<>
    <section className="why-we">
      <div className="container">
        <h2>Why Choose Missco as Your Compatibility Testing Company?</h2>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr33"></span>
              <h4>Tailored Testing Services</h4>
              <p>Missco professional testers offer tailored device compatibility testing specified to your projects and their specific requirements and needs.</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr34"></span>
              <h4>Professional Approach</h4>
              <p>Our compatibility team is comprised of highly experienced engineers and testers with skills in advanced tools, languages, and frameworks.</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr2"></span>
              <h4>Reliable Testing Team</h4>
              <p>Bringing years of experience to the table, Missco implements best practices to deliver desired outcomes that our customers demand.</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr30"></span>
              <h4>Covering Companies from Various Industries</h4>
              <p>Missco testers offer services for companies from different industries, including gaming, e-commerce, banking, financial services, real estate, insurance, Education, Audio, etc.</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr35"></span>
              <h4>Years of Compatibility Testing Experience</h4>
              <p>With years of experience in this industry, the Missco testing team offers compatibility testing services for all devices and platforms.</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr36"></span>
              <h4>Compatibility Testing with a Business Look</h4>
              <p>Understanding the importance of digital impressions in today's market, we plan and deliver our testing services with an eye on your industry and its requirements.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>);
}

export default CompatibilityServiceWhy;