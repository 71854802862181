import './App.css';
import './Css/Home.css';
import './Css/Project.css'
// import './Css/Default.css'
import './Css/Form.css'
import './Css/reviews.css'
import './Css/Profile.css'
import HomePage from './Components/Home/HomePage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutPage from './Components/Company/AboutUs/AboutPage';
import TestimonialPage from './Components/Company/Testimonials/TestimonalPage';
import MobileServicePage from './Components/Services/Mobile/MobileServicePage';
import AutomationServicePage from './Components/Services/Automation/AutomationServicePage';
import ManualServicePage from './Components/Services/Manual/ManualServicePage';
import ApiServicePage from './Components/Services/API/ApiServicePage';
import LoadPerformanceServicePage from './Components/Services/LoadPerformance/LoadPerformanceServicePage';
import CompatibilityServicePage from './Components/Services/Compatibility/CompatibilityServicePage';
import SecurityServicePage from './Components/Services/Security/SecurityServicePage';
import UnitServicePage from './Components/Services/Unit/UnitServicePage';
import CrossBrowserServicePage from './Components/Services/Cross Browser/CrossBrowserServicePage';
// import BlogPage from './Components/Blog/BlogPage'
import ContactPage from './Components/Contact/ContactPage'
import QaConsultServicePage from './Components/QaConsultation/QaConsultServicePage';
import ProjectPage from './Components/Projects/ProjectPage';
import Header from './Components/Common/Header';
import HomeFooter from './Components/Common/HomeFooter';
import { ActiveLinkProvider } from './Components/Common/ActiveLinkContext';
import ThankYouPage from './Components/Common/ThankYouPage';
import NotFound from './Components/Common/NotFound';
import CoreTeamPage from './Components/Company/CoreTeam/CoreTeamPage';
import { useEffect, useState } from 'react';
import Loader from './Components/Common/Loader';
import './Css/Loader.css'
import MobileAppDevelopmentPage from './Components/Services/MobileAppDevelopment/MobileAppDevelopmentPage';
import WebDevelopmentPage from './Components/Services/WebDevelopment/WebDevelopmentPage';

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data)
    const fetchData = async () => {
      // Replace the setTimeout with your actual asynchronous operation
      setTimeout(() => {
        setLoading(false); // Set loading to false when the operation is complete
      }, 1500);
    };

    fetchData();
  }, []);


  return (
    <Router>
      {loading ? (
        <Loader />
      ) : (
        <ActiveLinkProvider>
          <Header />

          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/reviews" element={<TestimonialPage />} />
            <Route path="/projects" element={<ProjectPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/qa-consultation" element={<QaConsultServicePage />} />
            <Route path="/thank-you" element={<ThankYouPage />} />
            <Route path="/core-team" element={< CoreTeamPage />} />

            {/* Services */}
            <Route path="/mobile-application-testing" element={<MobileServicePage />} />
            <Route path="/test-automation-services" element={<AutomationServicePage />} />
            <Route path="/manual-testing-services" element={<ManualServicePage />} />
            <Route path="/api-testing-services" element={<ApiServicePage />} />
            <Route path="/load-and-performance-testing" element={<LoadPerformanceServicePage />} />
            <Route path="/compatibility-testing-services" element={<CompatibilityServicePage />} />
            <Route path="/security-testing-services" element={<SecurityServicePage />} />
            <Route path="/unit-testing-services" element={<UnitServicePage />} />
            <Route path="/cross-browser-testing-services" element={<CrossBrowserServicePage />} />
            <Route path="/mobile-application-development" element={<MobileAppDevelopmentPage/>} />
            <Route path="/web-application-development" element={<WebDevelopmentPage />} />
            <Route path='/*' element={<NotFound />} />
            {/* <Route path="/blog" element={<BlogPage />} /> */}



          </Routes>
          <HomeFooter />
        </ActiveLinkProvider>

      )}
    </Router >
  );
}

export default App;
