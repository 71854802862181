export const mobileContent = {
    descriptionOne: {
        heading: "Mobile App Testing Services",
        description: "Miisco offers top-notch mobile app testing services to ensure your app is bug-free and ready for the global audience. Our services help you:",
        bullets: [
            "Identify and resolve bugs before reaching 6.92 billion smartphone users worldwide.",
            "Ensure compatibility across devices and operating systems.",
            "Optimize backend functionality.",
            "Enhance user experience through UI usability testing.",
            "Reduce app loading time by at least 30%.",
        ],
    },

    descriptionTwo: {
        heading: "Why You Need Mobile App Testing?",
        description: "In the digital age, users demand high-quality, glitch-free apps. Our mobile app testing company can help you:",
        bullets: [
            "Boost user retention rates by eliminating slow app issues.",
            "Maintain app performance on any network bandwidth.",
            "Save time on debugging and focus on innovation.",
            "Develop market-ready apps while reducing costs by eliminating bugs.",
            "Enhance security against potential vulnerabilities.",
            "Ensure your app fits the physical features of smartphones.",
        ],
    },

    whyMobile: {
        heading: "Why Choose Miisco for Mobile App Testing?",
        description: "We understand the importance of time in the competitive market. With Miisco's mobile app testing services, you get rapid and reliable results.",
        cards: {
            innovative: {
                title: "Flexible & Innovative Testing",
                description: "We offer a simplified platform with extensive tools for scalable and speedy testing, providing immediate results.",
            },

            experts: {
                title: "World-class Mobile App Testing Experts",
                description: "Our expert testers have years of experience in mobile app testing and can handle any platform, device, or OS.",
            },

            covering: {
                title: "Comprehensive Testing",
                description: "From automation framework development to CI/CD implementation, we cover all testing aspects to meet your needs.",
            },

            error: {
                title: "Early Error Detection",
                description: "We find and resolve bugs before users face glitches, ensuring a smooth user experience.",
            },

            saving: {
                title: "Cost-Effective Testing",
                description: "By testing at every stage, we keep costs low by preventing small glitches from becoming costly issues.",
            },

            scale: {
                title: "Large-scale Testing",
                description: "We test your apps extensively to ensure performance and compatibility with a wide range of devices.",
            },
        },
    },
    contact: {
        heading: "Contact Us",
        description: "With years of experience, we deliver premium quality and performance while saving you time and money. Get in touch with us for top-tier mobile app testing services!",
    },

    testingProcess: {
        heading: "Our Mobile App Testing Process",
        description: "Miisco follows a systematic process for software testing, ensuring on-time project completion. Our process includes several phases:",
        cards: {
            analysis: "Requirement Analysis",
            planning: "Test Planning",
            development: "Test Case Development",
            environment: "Test Environment Setup",
            execution: "Test Execution",
            closure: "Test Closure",
        },
    },

    blog: {
        one: {
            title: "Performance Testing Using JMeter for Beginners",
            description: "Learn about JMeter, an open-source tool for analyzing and measuring software performance, in this beginner's guide.",
        },
        two: {
            title: "Challenges in Mobile App Testing",
            description: "Explore the core challenges of mobile app testing and how to address them in app design and development projects.",
        },
        three: {
            title: "Mobile App Testing: Process & Benefits",
            description: "Discover the dynamic field of mobile app development, its challenges, and the benefits of seamless app functionality on various devices.",
        },
    },
};
