import React from "react";
import ApiServiceDescriptionOne from "./ApiServiceDescriptionOne";
import ApiServiceWhy from "./ApiServiceWhy";
import ApiServiceContact from "./ApiServiceContact";
import ApiTesting from "./ApiTesting";
import ApiServiceDescriptionTwo from "./ApiServiceDescriptionTwo";
import ApiServiceBanner from "./ApiServiceBanner";
import ApiServiceBlog from "./ApiServiceBlog";
import QaClient from "../../Common/QaClient";


function ApiServicePage() {
    return (<>
        <ApiServiceBanner />
        <ApiServiceDescriptionOne />
        <ApiServiceDescriptionTwo />
        <ApiServiceWhy />
        <ApiServiceContact />
        <ApiTesting />
        {/* <ApiServiceBlog /> */}
        <QaClient />
    </>);
}

export default ApiServicePage;