export const headContent = {

    websiteName: "Miisco",
    menubar: {
        home: "Home",
        company: {
            about: "About Us",
            testmonial: "Testimonials",
            project: "Projects",
            coreTeam: "Core Team"
        },
        services: {
            mobile: "Mobile Application Testing",
            automation: "Test Automation Services",
            manual: "Manual Testing Services",
            api: "API Testing Services",
            load: "Load and Performance Testing",
            security: "Security Testing Services",
            compatiblity: "Compatibility Testing Services",
            unit: "Unit Test Services",
            crosBrowser: "Cross Browser Testing",
            appDevelopement: "Mobile Application Development",
            webDevelopement: "Web Application Development"
        },
        qaConsultation: "QA Consultation",
        blog: "Blog",
        contactBtn: "Contact Us"
    }

}