
export default function WebDevelopmentBanner() {
    return (
        <section className="inner-banner container">
            <div>
                <h1>Website Development Services</h1>
                <p>
                    <span style={{ opacity: 0.8 }}>Services</span> &nbsp;-&nbsp; Website Development
                </p>
            </div>
        </section>
    )
}
