import { developmentContent } from "../../../Content/DevelopmentContent"
import MyHubSpotForm from "../../Common/MyHubSpotForm"

export default function WebDevelopmentContact() {
    const { contact } = developmentContent.WebDevelopment;
    return (
        <>
            <section className="home-contact">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-6 col-xl-5">
                            <h2>{contact.heading}</h2>
                            <p>{contact.description}</p>
                        </div>
                        <div className="col-lg-6">
                            <MyHubSpotForm />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
