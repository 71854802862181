import React from 'react'
import TestimonialFeedback from './TestimonalFeedback';
import TestimonialBanner from './TestimonalBanner';
import TestimonialContact from './TestimonalContact';
import QaClient from '../../Common/QaClient';


function TestimonialPage() {
    return (<>
        <body className="page-loaded">

            <TestimonialBanner />
            <TestimonialFeedback />
            <TestimonialContact />
            < QaClient />


        </body>

    </>);
}

export default TestimonialPage;