export const securityContent = {
    descriptionOne: {
        heading: "Security Testing Services",
        paragraph: "Ensure the security of your software, website, and mobile applications with Miisco's comprehensive security testing services. Our experts uncover vulnerabilities, prioritize risks, and protect confidential data. Our security testing company offers:",
        bullets: [
            "Comprehensive vulnerability assessment using scanning tools and manual testing",
            "Prioritization of vulnerabilities based on their criticality",
            "Source code scanning to detect security defects during development",
            "Simulation of attack scenarios to identify vulnerabilities",
            "Stress testing to ensure system resilience under heavy loads",
            "Alignment with regulatory standards for compliance",
            "Secure code review to pinpoint defects and vulnerabilities",
        ],
    },

    descriptionTwo: {
        heading: "Why You Need Security Testing Services",
        paragraph1: "Regular security testing can save your business thousands of dollars by preserving your brand's reputation and preventing security incidents. Miisco provides end-to-end security testing for APIs, web, mobile apps, and software to ensure business protection and client trust.",
        paragraph2: "Our security testing company helps you:",
        bullets: [
            "Protect your brand's reputation from potential damage",
            "Proactively identify and mitigate vulnerabilities",
            "Automate security processes for faster delivery",
            "Ensure compliance with global security standards",
            "Minimize cybercriminal opportunities and unauthorized access",
            "Prevent data breaches and secure sensitive information",
        ],
    },

    whySecurity: {
        heading: "Why Choose Miisco for Security Testing Services",
        cards: {
            extensive: {
                title: "Extensive Cybersecurity Experience",
                description: "With a strong cybersecurity background, Miisco serves clients from small businesses to large corporations.",
            },

            comprehensive: {
                title: "Comprehensive Security Testing",
                description: "We offer a wide range of IT security testing services, including analysis, requirement definition, exploitation, and actionable insights with remediation guidance.",
            },

            industries: {
                title: "Cross-Industry Expertise",
                description: "Miisco provides cybersecurity solutions to diverse industries, including finance, retail, healthcare, education, and travel.",
            },

            skilledTeam: {
                title: "Skilled Testing Team",
                description: "Our expert security testing team includes certified engineers and testers to protect your software from security violations.",
            },

            responsiveSupport: {
                title: "Responsive Support",
                description: "Count on Miisco's support team to address your questions and concerns throughout the testing process.",
            },

            costEffective: {
                title: "Affordable Protection",
                description: "Our security testing services are budget-friendly, making them suitable for small businesses.",
            },
        },
    },

    contact: {
        heading: "Contact Our Security Experts",
        description: "In an ever-evolving threat landscape, regular security and penetration testing are essential to protect your data, technologies, and privacy. Contact Miisco's experts and safeguard your business today.",
    },

    testingProcess: {
        heading: "Our Security Testing Process",
        description: "Miisco follows a systematic process to deliver software testing projects within set deadlines. Our process includes the following phases:",
        cards: {
            analysis: "Requirement Analysis",
            planning: "Test Planning",
            development: "Test Case Development",
            environment: "Test Environment Setup",
            execution: "Test Execution",
            closure: "Test Closure",
        },
    },

    blog: {
        one: {
            title: "Differences Between Mobile App and Web App Testing",
            description: "Explore the distinctions between mobile app testing and web app testing in this informative post, drawing on Miisco's extensive experience in QA testing.",
        },
        two: {
            title: "Manual Testing: Types, Pros, Cons, and How to Perform",
            description: "Embark on a journey to understand manual testing, its pros, cons, and effective execution in this comprehensive guide.",
        },
        three: {
            title: "Mobile App Testing on Virtual Device vs. Real Device",
            description: "Discover the differences between mobile app testing on virtual devices and real devices, and learn how to make the right choice for your testing needs.",
        },
    },
};
