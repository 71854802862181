import React from "react";
import { constants } from "../../../config/Config";


function CompatibilityServiceDescriptionTwo() {
  return (<>
    <section className="sr-top-section light-bg">
      <div className="container">
        <div className="row align-items-center flex-lg-row-reverse">
          <div className="col-lg-6">
            <div className="sr-top-section-img">
              <img src={`${constants.imagePath}/software-compatibility-testing-dashboard.webp`} alt="Software compatibility testing dashboard" loading="lazy" width="735px" height="495px" />
            </div>
          </div>
          <div className="col-lg-6">
            <h2>Why You Need Compatibility Testing Services</h2>
            <p>Missco delivers exhaustive OS compatibility testing services specifically designed for systems. Our services include the complete lifecycle of your software – from design to release and monitoring. We consider all the variables using fully equipped advanced compatibility testing tools that support the hardware, OS, website, and mobile app.</p>
            <p>Our compatibility testing company helps you:</p>
            <ul className="inr-listing">
              <li>Find vulnerable spots in the software prior to the release</li>
              <li>Eliminate compatibility problems after the launch</li>
              <li>Improve the quality of the app by detecting errors and issues</li>
              <li>Ensure optimal user experience and clients satisfaction</li>
              <li>Increase your business's profitability</li>
              <li>Ensure minimal to zero compatibility problems in the future</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </>);
}

export default CompatibilityServiceDescriptionTwo;