import React from "react";
import { securityContent } from "../../../Content/SecurityContent";
import { constants } from "../../../config/Config";


function SecurityServiceDescriptionOne() {

  const { descriptionOne } = securityContent;
  return (<>
    <section className="sr-top-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="sr-top-section-img">
              <img src={`${constants.imagePath}/zap-security-testing-services-alert-counts-table.webp`} alt="zap security testing services alert counts table" width="735px" height="495px" />
            </div>
          </div>
          <div className="col-lg-6">
            <h2>{descriptionOne.heading}</h2>
            <p>{descriptionOne.paragraph}</p>
            <ul className="inr-listing">
              {descriptionOne.bullets.map((value, index) => {
                return <li key={index}>{value}</li>
              })};
            </ul>
          </div>
        </div>
      </div>
    </section>
  </>);
}

export default SecurityServiceDescriptionOne;