import React from 'react'
import Form from '../../Common/Form';
import { testimonialContent } from '../../../Content/TestimonialContent';
import MyHubSpotForm from '../../Common/MyHubSpotForm';


function TestimonialContact() {

  const { contact } = testimonialContent;

  return (<>
    <section className="light-bg inner-contact top-shadow">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-6 col-xl-5">
            <h2>{contact.heading}</h2>
            <p>{contact.description}</p>
          </div>
          <div className='col-lg-6'>
            <MyHubSpotForm />
          </div>
        </div>
      </div>
    </section>

  </>);
}

export default TestimonialContact;