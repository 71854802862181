import React from "react";
import { apiContent } from "../../../Content/ApiContent";
import { constants } from "../../../config/Config";


function ApiServiceDescriptionOne() {

  const { descriptionOne } = apiContent;
  return (<>
    <section className="sr-top-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="sr-top-section-img">
              <img src={`${constants.imagePath}/delete-contact-by-api-Tester.webp`} alt="delete contact by api tester" width="735px" height="495px" />
            </div>
          </div>
          <div className="col-lg-6">
            <h2>{descriptionOne.heading}</h2>
            <p>{descriptionOne.paragraph}</p>
            <ul className="inr-listing">
              {descriptionOne.bullets.map((value, index) => {
                return <li key={index}>{value}</li>
              })};
            </ul>
          </div>
        </div>
      </div>
    </section>
  </>);
}

export default ApiServiceDescriptionOne;