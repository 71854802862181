import React from "react";


function MobileServiceBanner() {
  return (<>
    <section className="inner-banner container">
      <div>
        <h1>Mobile Application Testing Services</h1>
        <p>
          <span style={{ opacity: 0.8 }}>Services</span> &nbsp;-&nbsp; Mobile Application Testing
        </p>
      </div>
    </section>

  </>);
}

export default MobileServiceBanner;