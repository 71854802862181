import React from "react";
import QaConsultServiceDescriptionOne from "./QaConsultServiceDescriptionOne";
import QaConsultServiceWhy from "./QaConsultServiceWhy";
import QaConsultServiceContact from "./QaConsultServiceContact";
import QaConsultTesting from "./QaConsultTesting";
import QaConsultServiceDescriptionTwo from "./QaConsultServiceDescriptionTwo";
import QaConsultServiceBanner from "./QaConsultServiceBanner";
import QaConsultServiceBlog from "./QaConsultServiceBlog";
import QaClient from "../Common/QaClient";


function QaConsultServicePage() {
    return (<>
        <QaConsultServiceBanner />
        <QaConsultServiceDescriptionOne />
        <QaConsultServiceDescriptionTwo />
        <QaConsultServiceWhy />
        <QaConsultServiceContact />
        <QaConsultTesting />
        {/* <QaConsultServiceBlog /> */}
        <QaClient />
    </>);
}

export default QaConsultServicePage;