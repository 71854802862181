import { developmentContent } from "../../../Content/DevelopmentContent"

export default function WebDevelopmentProcess() {
    const { developmentProcess } = developmentContent.WebDevelopment;

    return (
        <>
            <section className="light-bg software-testing">
                <div className="container">
                    <h2>{developmentProcess.heading}</h2>
                    <p>{developmentProcess.description}</p>
                    <div className="row justify-content-center">
                        <div className="col-xxl-2 col-lg-4 col-6">
                            <div className="row flex-column">
                                <div className="round-card">
                                    <span className="sr1"></span>
                                </div>
                                <h5>{developmentProcess.cards.analysis}</h5>
                            </div>
                        </div>
                        <div className="col-xxl-2 col-lg-4 col-6">
                            <div className="row flex-column flex-xxl-column-reverse">
                                <div className="round-card">
                                    <span className="sr2"></span>
                                </div>
                                <h5>{developmentProcess.cards.planning}</h5>
                            </div>
                        </div>
                        <div className="col-xxl-2 col-lg-4 col-6">
                            <div className="row flex-column">
                                <div className="round-card">
                                    <span className="sr3"></span>
                                </div>
                                <h5>{developmentProcess.cards.development}</h5>
                            </div>
                        </div>
                        <div className="col-xxl-2 col-lg-4 col-6">
                            <div className="row flex-column flex-xxl-column-reverse">
                                <div className="round-card">
                                    <span className="sr4"></span>
                                </div>
                                <h5>{developmentProcess.cards.environment}</h5>
                            </div>
                        </div>
                        <div className="col-xxl-2 col-lg-4 col-6">
                            <div className="row flex-column">
                                <div className="round-card">
                                    <span className="sr5"></span>
                                </div>
                                <h5>{developmentProcess.cards.execution}</h5>
                            </div>
                        </div>
                        <div className="col-xxl-2 col-lg-4 col-6">
                            <div className="row flex-column flex-xxl-column-reverse">
                                <div className="round-card last">
                                    <span className="sr6"></span>
                                </div>
                                <h5>{developmentProcess.cards.closure}</h5>
                            </div>
                        </div>
                        {/* <div className="col-xxl-2 col-lg-4 col-6">
                            <div className="row flex-column flex-xxl-column-reverse">
                                <div className="round-card last">
                                    <span className="sr6"></span>
                                </div>
                                <h5>{developmentProcess.cards.update}</h5>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}
