export const projectContent = {
    pageTitle: "Projects",
    pageHeading: "Our Projects",
    pageDescription: "Discover our automation journey, revolutionizing processes across industries with cutting-edge technology. We've eliminated manual data errors, enhanced accuracy in complex business logic, and improved efficiency in areas from finances to supply chains. Our commitment to innovation drives continuous excellence.",
    projectsDescription: [
        {
            title: "SACS",
            description: "We're proud to have successfully tested the SACS website, dedicated to promoting psychological well-being. Our rigorous evaluation improved its user experience, enhancing tools and resources for a positive mental health journey.",
        },
        {
            title: "Everlight Radiology",
            description: "Explore our precise testing framework for Radiology, a healthcare and hospitalization website, ensuring optimized user experience and top-notch functionality.",
        },
        {
            title: "Tutorax",
            description: "Discover our sophisticated bot developed for Tutorax's website, streamlining operations and enhancing efficiency.",
        },
        {
            title: "Insurx",
            description: "We conducted rigorous testing on Insurx, evaluating intricate business logics and calculations for reliability and accuracy.",
        },
        {
            title: "TgQa",
            description: "Explore our comprehensive testing of TgQa, an E-commerce website, ensuring a seamless shopping experience for users.",
        },
        {
            title: "Tasco",
            description: "Discover our comprehensive testing of Tasco, an E-commerce website, ensuring a seamless shopping experience for users.",
        },
        {
            title: "Conformance Portal",
            description: "Explore our thorough testing of Conformance, a dedicated portal, ensuring it meets the highest standards of performance and functionality.",
        }
    ]
};
