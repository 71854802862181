import React from "react";
import UnitServiceDescriptionOne from "./UnitServiceDescriptionOne";
import UnitServiceWhy from "./UnitServiceWhy";
import UnitServiceContact from "./UnitServiceContact";
import UnitTesting from "./UnitTesting";
import UnitServiceDescriptionTwo from "./UnitServiceDescriptionTwo";
import UnitServiceBanner from "./UnitServiceBanner";
import UnitServiceBlog from "./UnitServiceBlog";
import QaClient from "../../Common/QaClient";


function UnitServicePage() {
    return (<>
        <UnitServiceBanner />
        <UnitServiceDescriptionOne />
        <UnitServiceDescriptionTwo />
        <UnitServiceWhy />
        <UnitServiceContact />
        <UnitTesting />
        {/* <UnitServiceBlog /> */}
        <QaClient />
    </>);
}

export default UnitServicePage;