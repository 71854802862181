import React from "react";
import { Link } from "react-router-dom";




function NotFound() {
    return (<>
        <section className="home-about inner-about home-banner">
            <div className="container">
                <div className="page-not-found ">
                    <div className="row align-items-center">
                        <h2>404. That's an error.</h2>
                        <p className="title-text">The requested URL was not found on this server. It may be deleted because the service doesn't exist anymore, or the URL may be incorrect. <br /><strong>Please visit following links which are useful and bug-free:</strong></p>
                    </div>
                    <div className="row align-items-center">
                        <Link style={{ textAlign: "center" }} to="/" className="btn">Home Page</Link>
                    </div>
                </div>

            </div>
        </section>
    </>);
}

export default NotFound;