import React from "react";
import CompatibilityServiceDescriptionOne from "./CompatibilityServiceDescriptionOne";
import CompatibilityServiceWhy from "./CompatibilityServiceWhy";
import CompatibilityServiceContact from "./CompatibilityServiceContact";
import CompatibilityTesting from "./CompatibilityTesting";
import CompatibilityServiceDescriptionTwo from "./CompatibilityServiceDescriptionTwo";
import CompatibilityServiceBanner from "./CompatibilityServiceBanner";
import CompatibilityServiceBlog from "./CompatibilityServiceBlog";
import QaClient from "../../Common/QaClient";


function CompatibilityServicePage() {
    return (<>
        <CompatibilityServiceBanner />
        <CompatibilityServiceDescriptionOne />
        <CompatibilityServiceDescriptionTwo />
        <CompatibilityServiceWhy />
        <CompatibilityServiceContact />
        <CompatibilityTesting />
        {/* <CompatibilityServiceBlog /> */}
        <QaClient />
    </>);
}

export default CompatibilityServicePage;