import MobileAppDevelopmentBanner from "./MobileAppDevelopmentBanner"
import MobileAppDevelopmentContact from "./MobileAppDevelopmentContact"
import MobileAppDevelopmentBannerwhy from "./MobileAppDevelopmentWhy"
import MobileApplicationDevelopmentdescription from "./MobileApplicationDevelopmentDescription"
import MobileAppDevelopmentBlog from "./MobileDevelopmentBlog"
import MobileAppDevelopmentProcess from "./MobileAppDevelopmentProcess"
import QaClient from "../../Common/QaClient"

export default function MobileAppDevelopmentPage() {
    return (
        <>
        <MobileAppDevelopmentBanner />
        <MobileApplicationDevelopmentdescription />
        <MobileAppDevelopmentBannerwhy />
        <MobileAppDevelopmentContact />
        <MobileAppDevelopmentProcess />
        {/* <MobileAppDevelopmentBlog /> */}
        <QaClient />
        </>
    )
}