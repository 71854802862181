import React from "react";
import { crossBrowserContent } from "../../../Content/CrossBrowserContent";


function CrossBrowserServiceWhy() {

  const { whyCrossBrowser } = crossBrowserContent;
  return (<>
    <section className="why-we">
      <div className="container">
        <h2>{whyCrossBrowser.heading}</h2>
        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr38"></span>
              <h4>{whyCrossBrowser.cards.efficacy.title}</h4>
              <p>{whyCrossBrowser.cards.efficacy.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr39"></span>
              <h4>{whyCrossBrowser.cards.report.title}</h4>
              <p>{whyCrossBrowser.cards.report.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr40"></span>
              <h4>{whyCrossBrowser.cards.seeWhat.title}</h4>
              <p>{whyCrossBrowser.cards.seeWhat.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr41"></span>
              <h4>{whyCrossBrowser.cards.fool.title}</h4>
              <p>{whyCrossBrowser.cards.fool.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr42"></span>
              <h4>{whyCrossBrowser.cards.cycleTest.title}</h4>
              <p>{whyCrossBrowser.cards.cycleTest.description}</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6">
            <div className="card">
              <span className="sr30"></span>
              <h4>{whyCrossBrowser.cards.industry.title}</h4>
              <p>{whyCrossBrowser.cards.industry.description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>);
}

export default CrossBrowserServiceWhy;