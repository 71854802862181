
import React, { useEffect, useState } from "react";
import { content } from "../../Content/HomeContent";

function HomeServices() {

    //Animation Effect On Load on Content
    const [animationLeft, setAnimationLeft] = useState("");
    const [animationRight, setAnimationRight] = useState("");
    const [animationUp, setAnimationUp] = useState("");
    const [visible, setVisible] = useState("hidden")
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        function triggerOnScroll() {
            setAnimationLeft("animate__fadeInLeft");
            setAnimationRight("animate__fadeInRight");
            setAnimationUp("animate__fadeInUp")
            setIsAnimated(true);
            setVisible("") // Set isAnimated to true when the animation is triggered
        }

        window.addEventListener("scroll", function () {
            const scrollPosition = window.scrollY; // Vertical scroll position
            if (scrollPosition > 3200 && !isAnimated) {
                triggerOnScroll();
            }
        });
    }, [isAnimated]);
    //..

    const { services } = content;
    return (<>
        <section className="home-services top-shadow">
            <div className={`container ${visible}`}>
                <div className={`animate__animated ${animationUp}`}>
                    <h2>{services.heading}</h2>
                    <p>{services.description}</p>
                </div>
                <div className="row justify-content-center">
                    <div className={`col-lg-3 col-sm-6 animate__animated ${animationLeft}`}><a href="/mobile-application-testing" className="card" title="Mobile Application Testing"> <span className="sr1"></span>
                        <p>Mobile Application Testing</p>
                    </a></div>
                    <div className={`col-lg-3 col-sm-6 animate__animated ${animationLeft}`}><a href="/test-automation-services" className="card" title="Test Automation Services"> <span className="sr2"></span>
                        <p>Test Automation Services</p>
                    </a></div>
                    <div className={`col-lg-3 col-sm-6 animate__animated ${animationRight}`}><a href="/manual-testing-services" className="card" title="Manual Testing Services"> <span className="sr3"></span>
                        <p>Manual Testing Services</p>
                    </a></div>
                    <div className={`col-lg-3 col-sm-6 animate__animated ${animationRight}`}><a href="/api-testing-services" className="card" title="API Testing Services"> <span className="sr4"></span>
                        <p>API Testing <br />Services</p>
                    </a></div>
                    <div className={`col-lg-3 col-sm-6 animate__animated ${animationLeft}`}><a href="/load-and-performance-testing" className="card" title="Load and Performance Testing"> <span className="sr5"></span>
                        <p>Load and Performance Testing</p>
                    </a></div>
                    <div className={`col-lg-3 col-sm-6 animate__animated ${animationLeft}`}><a href="/security-testing-services" className="card" title="Security Testing Services"> <span className="sr6"></span>
                        <p>Security Testing Services</p>
                    </a></div>
                    <div className={`col-lg-3 col-sm-6 animate__animated ${animationRight}`}><a href="/compatibility-testing-services" className="card" title="Compatibility Testing Services"> <span className="sr7"></span>
                        <p>Compatibility Testing Services</p>
                    </a></div>
                    <div className={`col-lg-3 col-sm-6 animate__animated ${animationRight}`}><a href="/unit-testing-services" className="card" title="Unit Test Service"> <span className="sr8"></span>
                        <p>Unit Test Service</p>
                    </a></div>
                    <div className={`col-lg-3 col-sm-6 animate__animated ${animationUp}`}><a href="/cross-browser-testing-services" className="card" title="Cross Browser Testing Service"> <span className="sr9"></span>
                        <p>Cross Browser Testing Service</p>
                    </a></div>
                </div>
            </div>
        </section>
    </>)

}


export default HomeServices;