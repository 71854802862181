import React from "react";
import MobileServiceBanner from "./MobileServiceBanner";
import MobileServiceDescriptionOne from "./MobileServiceDescriptionOne";
import MobileServiceDescriptionTwo from "./MobileServiceDescriptionTwo";
import MobileServiceWhy from "./MobileServiceWhy";
import MobileServiceContact from "./MobileServiceContact";
import MobileApplicationTesting from "./MobileApplicationTesting";
import MobileServiceBlog from "./MobileServiceBlog";
import QaClient from "../../Common/QaClient";


function MobileServicePage() {
  return (<>
    <MobileServiceBanner />
    <MobileServiceDescriptionOne />
    <MobileServiceDescriptionTwo />
    <MobileServiceWhy />
    <MobileServiceContact />
    <MobileApplicationTesting />
    {/* <MobileServiceBlog /> */}
    <QaClient />
  </>);
}

export default MobileServicePage;