import React from "react";
import Form from '../Common/Form'
import { constants } from "../../config/Config";
import { contactUsContent } from "../../Content/ContactUsContent";
import MyHubSpotForm from "../Common/MyHubSpotForm";

function ContactForm() {

  const { aboutCompany, description, heading } = contactUsContent;
  return (<>
    <section className="contact-us-block">
      <div className="container">
        <h2>{heading}</h2>
        <p className="title-text">{description} </p>
        <div className="row justify-content-center">
          <div className="col-lg-12 col-xl-9">
            <div className="card">
              <MyHubSpotForm />
            </div>
          </div>
        </div>

        {aboutCompany.map((value, index) => {
          return <div key={index}>
            <h4>{value.country}</h4>
            <p className="contact-text">{value.address}</p>
            <div className="row justify-content-center">
              <div className="col-auto">
                <a className="callus" href="tel:+917940327195">
                  <img src={`${constants.imagePath}/ic-call.webp`} alt="Missco India Number" loading="lazy" /> {value.phone} </a>
              </div>
              <div className="col-auto">
                <a className="mailus" href="mailto:info@Missco.com">
                  <img src={`${constants.imagePath}/ic-mail.webp`} alt="Missco Email ID" loading="lazy" /> {value.email} </a>
              </div>
            </div>
          </div>
        })}
      </div>
    </section>

  </>);
}

export default ContactForm;