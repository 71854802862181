import axios from "axios";


// const isLocalhost = Boolean(
//     window.location.hostname === "localhost" ||
//     window.location.hostname === "[::1]" ||
//     window.location.hostname.match(
//         /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
// );

const API_URL = true ? "http://localhost:3636/api/" : "http://api.miiscollp.com/api/";

export const Axios = axios.create({ withCredentials: true, baseURL: API_URL })
console.log(API_URL);
export const constants = {

    imagePath: `${process.env.PUBLIC_URL}/images`,
    postImagePath: `${process.env.PUBLIC_URL}/post`,
    profileImagepath: `${process.env.PUBLIC_URL}/profile`,
    backendBaseUrl: 'http://localhost:3636/api'
}